import { AlegraContact } from "alegra-node-api";
import { Chat, Clipboard, Mail, Search, Phone } from "heroicons-react";
import React, { useState } from "react";
import Layout from "../../layout/Layout";
import { Alert, TypeOfAlert } from "../../ui/Alert";
import Dropdown from "../../ui/Dropdown";
import { api } from "../../utils/api";
import { createCustomerUrl } from "../../utils/customerContext";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const ContextMenu = ({ contact }: { contact: AlegraContact }) => {
  const [copyText, setCopyText] = useState("Copiar enlace");
  const history = useHistory();
  const clientUrl = createCustomerUrl(contact);

  // const chatUrl = `https://api.whatsapp.com/send?phone=+57${contact.phonePrimary}`;
  const chatUrl = `https://web.whatsapp.com/send?phone=+57${contact.phonePrimary}`;

  const CONTEXT_BUTTONS = [
    {
      name: copyText,
      Icon: Clipboard,
      onClick: () => copyToClipBoard(clientUrl),
    },
    {
      name: "Abrir Chat",
      Icon: Chat,
      disabled: !contact.phonePrimary,
      onClick: () => window?.open(chatUrl, "_blank")?.focus(),
    },
    {
      name: "Crear orden de compra",
      onClick: () => {
        history.push(`/orders/create/${contact.id}`);
      },
    },
  ];

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopyText("Copiado!");
    } catch (err) {
      setCopyText("Failed to copy!");
    }
  };

  return (
    <Dropdown>
      <div className="py-1">
        {CONTEXT_BUTTONS.map((button, idx) => (
          <button
            key={idx}
            className="w-full flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            disabled={button.disabled}
            onClick={button.onClick}
          >
            {button.Icon && <button.Icon size={20} className="text-gray-400 mr-3" />}
            {button.name}
          </button>
        ))}
      </div>
    </Dropdown>
  );
};

function Customers() {
  const [query, setQuery] = useState<string>("");
  const { data: contacts } = api.contacts.get({ query });

  const hadleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const value = form?.querySelector<HTMLInputElement>("[name=query]")?.value;
    setQuery(value || "");
  };

  return (
    <Layout
      title="Clientes"
      middle={
        <form action="" onSubmit={hadleSubmit} className="flex items-center">
          <input type="text" name="query" className="border p-1 rounded text-sm" placeholder="Buscar" />
          <button className="btn-base ml-2 bg-gray-300 self-stretch">
            <Search size={18} className="mr-1" /> Buscar
          </button>
        </form>
      }
    >
      <div>
        <div className="bg-white shadow sm:rounded-md">
          <ul>
            {contacts?.data.length === 0 && (
              <Alert type={TypeOfAlert.info}>
                <p>No se encontraron clients</p>
              </Alert>
            )}
            {contacts?.data.map((contact, index) => (
              <li key={index}>
                <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out border-b border-gray-200">
                  <div className="px-4 py-2 flex items-center sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center justify-between">
                      <Link to={`customers/${contact.id}`} className="pr-2 sm:flex-1" style={{ maxWidth: "60%" }}>
                        <div className="text-sm leading-5 font-medium">
                          <div className="text-sm leading-5 font-medium text-indigo-600 truncate">{contact.name}</div>

                          <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                            {contact.email && (
                              <span className="hidden sm:flex items-center text-sm mr-2">
                                <Mail size={18} className="text-gray-400 mr-1" />
                                {contact.email}
                              </span>
                            )}
                            {contact.phonePrimary && (
                              <span className="flex items-center text-sm mr-2">
                                <Phone size={15} className="text-gray-400 mr-1" />
                                {contact.phonePrimary}
                              </span>
                            )}
                          </div>
                        </div>
                      </Link>
                      <div className="ml-2 flex-shrink-0 flex">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {contact.priceList?.name}
                        </span>
                      </div>
                      <div className="ml-4">
                        <ContextMenu contact={contact} />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
}

export default Customers;
