import React from "react";

interface IProps {
  title: string;
  middle?: React.ReactNode;
}

function PageHeader({ title, middle }: IProps) {
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
        <h1 className="text-1xl font-bold leading-tight text-gray-900">{title}</h1>
        {middle && <div>{middle}</div>}
      </div>
    </header>
  );
}

export default PageHeader;
