import { useQuery } from "@apollo/client";
import currency from "currency.js";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { Alert, TypeOfAlert } from "../../ui/Alert";
import OrderDetailStocks from "../../ui/OrderDetailStocks";
import { ExpirationBadge, LotBadge } from "../../ui/OrderRow";
import { GET_ORDER } from "../../utils/gqlQueries";

const formatMoney = (n: number) => currency(n, { formatWithSymbol: true, precision: 0 }).format();
const getDiscount = (n: number, discount: number | string = 0) => n - n * (Number(discount) / 100);

function OrderDetails() {
  const params = useParams<{ id: string }>();

  const { data } = useQuery<{ orders_by_pk: HasuraOrder }>(GET_ORDER, { variables: { id: params.id } });
  const order = data?.orders_by_pk;

  if (!order) return null;
  return (
    <Layout title={order.client?.name}>
      {order.comments && (
        <Alert title="Sede" type={TypeOfAlert.info} className="mb-4">
          <p>{order.comments}</p>
        </Alert>
      )}
      {order.expiration && order.lot ? (
        <div className="flex">
          {order.expiration && <ExpirationBadge order={order} />}
          {order.lot && <LotBadge order={order} />}
        </div>
      ) : null}
      <div>
        <div>
          <div className="hidden sm:block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <div className="px-4 py-4 flex items-center sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between text-sm text-gray-600">
                <div className="pr-2 w-full sm:w-2/6">Producto</div>
                <div className="pr-2 mt-4 w-1/6 flex-shrink-0 sm:mt-0">Cantidad</div>
                <div className="pr-2 mt-4 w-1/6 flex-shrink-0 sm:mt-0">Precio</div>
                <div className="pr-2 mt-4 w-1/6 flex-shrink-0 sm:mt-0">Impuestos</div>
                <div className="mt-4 w-1/6 flex-shrink-0 sm:mt-0">Subtotal</div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow sm:rounded-md">
            <ul>
              {order?.items?.map((item, idx: number) => {
                const discounted = Number(item.discount) > 0;
                return (
                  <li key={idx}>
                    <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out border-b border-gray-200">
                      <div className="px-4 py-4 flex flex-col sm:flex-row items-center sm:px-6">
                        <div className="w-full sm:w-2/6 pr-2">
                          <div className="text-sm leading-5 font-medium">{item.name}</div>
                        </div>
                        <div className="w-full min-w-0 flex-1 flex sm:items-center justify-between">
                          <div className="pr-2 mt-4 w-1/5 sm:w-1/6 flex-shrink-0 sm:mt-0">
                            <div className="uppercase text-xs text-gray-600 sm:hidden">CANT.</div>
                            <span className="font-mono text-sm">{item.qty}</span>
                          </div>
                          <div className="pr-2 mt-4 w-1/4 sm:w-1/6 flex-shrink-0 sm:mt-0 flex flex-col justify-center items-end">
                            <div className="uppercase text-xs text-gray-600 sm:hidden">Precio</div>
                            <span
                              className={`font-mono ${discounted ? "line-through text-xs text-gray-600" : "text-sm"}`}
                            >
                              {formatMoney(item.price)}
                            </span>
                            {discounted && (
                              <span className="font-mono text-sm">
                                {formatMoney(getDiscount(item.price, item.discount))}
                              </span>
                            )}
                          </div>
                          <div className="pr-2 mt-4 w-1/4 sm:w-1/6 flex-shrink-0 sm:mt-0 flex flex-col justify-center items-end">
                            <div className="uppercase text-xs text-gray-600 sm:hidden">IVA</div>
                            <span
                              className={`font-mono ${discounted ? "line-through text-xs text-gray-600" : "text-sm"}`}
                            >
                              {formatMoney(item.tax)}
                            </span>
                            {discounted && (
                              <span className="font-mono text-sm">
                                {formatMoney(getDiscount(item.tax, item.discount))}
                              </span>
                            )}
                          </div>
                          <div className="pr-2 mt-4 w-1/4 sm:w-1/6 flex-shrink-0 sm:mt-0 flex flex-col justify-center items-end">
                            <div className="uppercase text-xs text-gray-600 sm:hidden">Subtotal</div>
                            <span
                              className={`font-mono ${discounted ? "line-through text-xs text-gray-600" : "text-sm"}`}
                            >
                              {formatMoney(item.subtotal)}
                            </span>
                            {discounted && (
                              <span className="font-mono text-sm">
                                {formatMoney(getDiscount(item.subtotal, item.discount))}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <OrderDetailStocks order={order} item={item} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <div className="py-4 flex items-center border-t">
              <div className="min-w-0 flex-1 ">
                <div className="w-4/12"></div>
                <div className="mt-4 text-right font-mono flex">
                  <span className="text-sm text-gray-600 mr-5 flex-grow">Total Items</span>
                  {order.totalQty}
                </div>
                <div className="mt-4 text-right font-mono">
                  <span className="text-sm text-gray-600 mr-5">Subtotal</span>
                  {formatMoney(order.totalPrice)}
                </div>
                {order.totalTaxByType ? (
                  Object.entries(order.totalTaxByType).map(([key, value]) => (
                    <Fragment key={key}>
                      <div className="mt-4 text-right font-mono">
                        <span className="text-sm text-gray-600 mr-5">{key}</span>
                        {formatMoney(value)}
                      </div>
                    </Fragment>
                  ))
                ) : (
                  // Backwards compatibility for previous single tax orders
                  <div className="mt-4 text-right font-mono">
                    <span className="text-sm text-gray-600 mr-5">IVA</span>
                    {formatMoney(order.totalTax)}
                  </div>
                )}
                <div className="mt-4 text-right font-mono font-bold text-lg">
                  <span className="text-sm text-gray-600 mr-5">Total</span>
                  {formatMoney(order.total)}
                </div>
              </div>
            </div>
            {order.totalDiscount > 0 && (
              <div className="py-4 flex items-center justify-end font-mono font-bold ">
                <span className="text-sm text-gray-600 mr-5">Total Descuentos</span>
                {formatMoney(order.totalDiscount)}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OrderDetails;
