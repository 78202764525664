import axios from "axios";
import { useQuery, useMutation } from "react-query";
import {
  AlegraMetadataResponse,
  AlegraContact,
  AlegraItem,
  AlegraConstactsQueryParams,
  AlegraEstimatesCreateParams,
  AlegraEstimateSendEmailParams,
  AlegraEstimate,
} from "alegra-node-api";
import dayjs from "dayjs";
import { PRODS_ORDER } from "./constants";
import { PRODS } from "./hardprods";

export const axiosInstance = axios.create({
  baseURL: "/.netlify/functions",
});
axiosInstance.defaults.headers["Content-Type"] = "application/json; charset=utf-8";

export const useContacts = (params?: AlegraConstactsQueryParams) => {
  return useQuery(
    ["AlegraContacts", params],
    async () => {
      const res = await axiosInstance.get<AlegraMetadataResponse<AlegraContact[]>>("/alegra", {
        params: { method: "contacts.get", params: { metadata: true, ...params } },
      });
      return res.data;
    },
    { staleTime: 80000 }
  );
};

export const useContactById = (id?: string) => {
  return useQuery(["AlegraContactById", id], async () => {
    if (!id) return null;
    try {
      const res = await axiosInstance.get<AlegraContact>("/alegra", { params: { method: "contacts.getById", id } });
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  });
};

export const useProducts = () => {
  return useQuery(
    "AlegraItems",
    async () => {
      // const res = await axiosInstance.get<AlegraItem[]>("/alegra", { params: { method: "items.get" } });
      // const orderedItems = res.data.sort((a, b) => (PRODS_ORDER.indexOf(a.id) > PRODS_ORDER.indexOf(b.id) ? 1 : -1));
      // console.log(orderedItems);
      // return orderedItems;
      const orderedItems = PRODS.sort((a, b) => (PRODS_ORDER.indexOf(a.id) > PRODS_ORDER.indexOf(b.id) ? 1 : -1));
      return orderedItems as unknown as AlegraItem[];
    },
    { staleTime: 80000 }
  );
};

export const useEstimateCreate = () => {
  return useMutation<AlegraEstimate, Partial<AlegraEstimatesCreateParams>>(async (data) => {
    const res = await axiosInstance.post<AlegraEstimate>("/alegraAdmin", { method: "estimates.create", data });
    return res.data;
  });
};

export const useEstimateGet = (id?: string) => {
  return useQuery(["getEstimateAlegra", id], async () => {
    const res = await axiosInstance.post<AlegraEstimate>("/alegraAdmin", {
      method: "estimates.create",
      data: { fields: "invoices" },
      id,
    });
    return res.data;
  });
};

export const useEstimateUpdate = () => {
  return useMutation<AlegraEstimate, { id: string; data: Partial<AlegraEstimatesCreateParams> }>(
    async ({ id, data }) => {
      const res = await axiosInstance.put<AlegraEstimate>("/alegraAdmin", { method: "estimates.update", data, id });
      return res.data;
    }
  );
};

export const useSendEmail = () => {
  return useMutation<void, Partial<{ id: string; data: AlegraEstimateSendEmailParams }>>(async ({ id, data }) => {
    const res = await axiosInstance.post<any>("/alegraAdmin", { method: "estimates.sendEmail", id, data });
    console.log(res);
  });
};

export const useSettings = () => {
  return useQuery(
    "settings",
    async () => {
      const res = await axiosInstance.get<FaunaSettings>("/settings");
      return res.data;
    },
    { staleTime: 80000 }
  );
};

export const useSettingsUpdate = () => {
  return useMutation<void, Partial<FaunaSettingsData>>(async (data) => {
    await axiosInstance.put<FaunaSettings>(`/settings`, data);
  });
};

export const useSendNotify = () => {
  return useMutation<any, string>(async (message) => {
    const res = await axiosInstance.post(`/notify`, { message });
    return res;
  });
};

export const orderToEstimate = (order: HasuraOrder): AlegraEstimatesCreateParams => {
  return {
    date: dayjs().format("YYYY-MM-DD"),
    dueDate: dayjs().format("YYYY-MM-DD"),
    client: order.client.id,
    anotation: order.comments,
    items: orderItemsToEstimateItems(order.items),
  };
};

export const orderItemsToEstimateItems = (items: HasuraOrderItem[]) => {
  return items.map(({ id, price, qty, discount }) => {
    const tax = PRODS.find((p) => p.id === Number(id))?.tax || [];
    return { id, price, quantity: Number(qty), tax: tax, discount };
  });
};

export const api = {
  axiosInstance,
  contacts: {
    get: useContacts,
    getById: useContactById,
  },
  products: {
    get: useProducts,
  },
  estimates: {
    get: useEstimateGet,
    create: useEstimateCreate,
    update: useEstimateUpdate,
    sendEmail: useSendEmail,
  },
  settings: {
    get: useSettings,
    update: useSettingsUpdate,
  },
  notify: {
    send: useSendNotify,
  },
};
