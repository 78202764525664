import dayjs from "dayjs";
import { Archive, Calendar, DocumentText, Hashtag, LocationMarker, Tag } from "heroicons-react";
import TimeAgo from "javascript-time-ago";
import React from "react";
import { Link } from "react-router-dom";
import formatMoney from "../utils/formatMoney";
import { lotExpDateToDisplayShortFormat, lotIdToFormat } from "../utils/stocking";
import { OrderPaymentBadge, OrderStatusBadge } from "./OrderBadges";

export interface IOrderRowProps {
  order: HasuraOrder;
  linkResolver: (id: string) => string;
  contextMenu?: React.ReactNode;
  showPrice?: boolean;
  showCity?: boolean;
  showEstimate?: boolean;
  showInvoice?: boolean;
  showLot?: boolean;
  showTotalQty?: boolean;
  date?: React.ReactNode;
}

export const ORDER_ROW_PRESETS: { ADMIN: Partial<IOrderRowProps> } = {
  ADMIN: {
    showPrice: true,
    showCity: true,
    showEstimate: true,
    showInvoice: true,
    showLot: true,
  },
};

interface IRowBadges {
  order: HasuraOrder;
}

const Total = ({ order }: IRowBadges) => (
  <div className="flex items-center ">
    <OrderPaymentBadge payment={order.payment} />
    <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-800">{formatMoney(order.total)}</div>
  </div>
);

const City = ({ order }: IRowBadges) => (
  <div className="mr-2 sm:mr-3 space-x-2 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
    <LocationMarker className=" text-gray-400" size={15} />
    <span className="whitespace-no-wrap">{order?.client?.address?.city}</span>
    {order.comments ? <span className="text-gray-800 ml-1 text-xs">{order.comments}</span> : null}
  </div>
);

const Estimate = ({ order }: IRowBadges) =>
  !order.invoice?.id ? (
    <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
      <Hashtag className="mr-1 text-gray-400" size={15} />
      {order?.estimate?.number}
    </div>
  ) : null;

const Invoice = ({ order }: IRowBadges) =>
  order.invoice?.id ? (
    <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
      <>
        <DocumentText className="mr-1 text-gray-400" size={15} />
        {order?.invoice?.numberTemplate?.prefix}
        {order?.invoice?.numberTemplate?.number}
      </>
    </div>
  ) : null;

export const LotBadge = ({ order }: IRowBadges) => (
  <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
    <Tag className="mr-1 text-gray-400" size={15} />
    <span>Lot:</span> {order?.lot}
  </div>
);

export const LotBadgeV2 = ({ order }: IRowBadges) => {
  const lotDescriptions = order.lots?.map(
    (lot) => lotIdToFormat(lot.lotId) + ":" + lotExpDateToDisplayShortFormat(lot.expDate)
  );
  const uniqueLotDescriptions = Array.from(new Set(lotDescriptions)).map((i) => {
    return {
      lotId: i.split(":")[0],
      expDate: i.split(":")[1],
    };
  });

  return (
    <div className="mr-2 sm:mr-3 flex items-center text-xs leading-5 text-gray-700 sm:mt-0">
      <Tag className="mr-1 text-gray-400" size={15} />
      <span className="mr-1">Lots: </span>
      {uniqueLotDescriptions?.map((lot, idx) => (
        <span className="mr-1" key={idx}>
          <span className="text-black mr-1 font-semibold">{lot.lotId}</span> <span className="">{lot.expDate}</span>
          {idx < uniqueLotDescriptions.length - 1 ? " • " : " "}
        </span>
      ))}
    </div>
  );
};

const TotalQty = ({ order }: IRowBadges) => (
  <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
    <Archive className="mr-1 text-gray-400" size={15} />
    <span>Qty:</span> <span className="font-bold">{order?.totalQty}</span>
  </div>
);

export const ExpirationBadge = ({ order }: IRowBadges) => (
  <div className="mr-2 sm:mr-3 flex items-center text-sm leading-5 text-gray-700 sm:mt-0">
    <Calendar className="mr-1 text-gray-400" size={15} />
    V: <span className="font-bold"> {order?.expiration}</span>
  </div>
);

export default function OrderRow({
  order,
  contextMenu,
  linkResolver,
  showPrice,
  showCity,
  showEstimate,
  showInvoice,
  showLot,
  showTotalQty,
  date,
}: IOrderRowProps) {
  const timeAgo = new TimeAgo("es-CO");
  return (
    <li>
      <div className={`block`}>
        <div className="px-2 sm:px-4 py-2 flex items-center border-t border-gray-200">
          <div style={{ width: 2 }} className="bg-red-600 h-full"></div>
          <Link className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between" to={linkResolver(order.id)}>
            <div>
              <div className="text-sm leading-5 font-medium truncate">{order.client.name}</div>
              <div className="mt-2 flex">
                <div className="flex items-center text-sm leading-5 text-gray-500 flex-wrap">
                  {showPrice && !order.isReplacement && <Total order={order} />}
                  {showTotalQty && order.totalQty && <TotalQty order={order} />}
                  {showLot ? (
                    <>
                      {order.expiration && <ExpirationBadge order={order} />}
                      {order.lot && <LotBadge order={order} />}
                    </>
                  ) : null}
                  {showLot && order.lots?.length > 0 ? <LotBadgeV2 order={order} /> : null}
                  {showEstimate && order.estimate && <Estimate order={order} />}
                  {showInvoice && order.invoice && <Invoice order={order} />}
                </div>
              </div>
              {showCity && (
                <div className="mt-2">
                  <City order={order} />
                </div>
              )}
            </div>
            <div className="mt-4 flex-shrink-0 sm:mt-0">
              <div className="sm:text-right flex sm:block items-center">
                {order.isReplacement && (
                  <span className="badge bg-gray-100 text-gray-600 border-gray-300 mr-2">Reposición</span>
                )}
                <OrderStatusBadge status={order.status} />
                <div className="ml-3 sm:mt-2 text-sm leading-5 text-gray-600">
                  <span className="text-xs">
                    {date ? (
                      date
                    ) : (
                      <time dateTime={dayjs(order.created_at).format("YYYY-MM-DD")}>
                        {timeAgo.format(new Date(order.created_at))}
                      </time>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Link>
          <div className="ml-5 flex-shrink-0">{contextMenu}</div>
        </div>
      </div>
    </li>
  );
}
