import dayjs from "dayjs";
import { findMapperMatch } from "./prodsUtils";

export const DATE_FORMAT_STOCK = "YYYY-MM-DD";
export const LOT_FORMAT = "DDMMYY";

let availableStockReduce: HasuraStock[] = [];

export const lotIdToFormat = (lotId: string) => dayjs(lotId).format(LOT_FORMAT);
export const lotExpDateToDisplayFormat = (lotId: string) => dayjs(lotId).format("MM/YYYY");
export const lotExpDateToDisplayShortFormat = (lotId: string) => dayjs(lotId).format("MM/YY");

const getExtendedItems = (items: HasuraOrderItem[]) => {
  const newItems = items.map((item) => {
    const prodSlug = findMapperMatch(item.name)?.slug;

    const usedLots: UsedLotInfo[] = [];

    let stockToFill = item.qty;

    const updatedStock = availableStockReduce.map((stock) => {
      if (stock.unusedQty > 0 && stockToFill > 0 && stock.product === prodSlug) {
        const newUnusedQty = stock.unusedQty - stockToFill;

        if (newUnusedQty >= 0) {
          usedLots.push({
            ...stock,
            taken: stockToFill,
            unusedQty: newUnusedQty,
          });
          stockToFill = 0;
        } else if (newUnusedQty < 0) {
          usedLots.push({
            ...stock,
            taken: stock.unusedQty,
            unusedQty: 0,
          });
          stockToFill = Math.abs(newUnusedQty);
        }

        return {
          ...stock,
          unusedQty: newUnusedQty < 0 ? 0 : newUnusedQty,
        };
      }
      return stock;
    });
    availableStockReduce = updatedStock;

    return {
      ...item,
      usedLots,
      missingUnits: stockToFill,
    };
  });

  return newItems;
};

export const mapOrdersWithStock = (orders: HasuraOrder[], stock: HasuraStock[] = []) => {
  availableStockReduce = [...stock];
  return orders.map((order) => {
    const newItems = getExtendedItems(order.items);
    return {
      ...order,
      items: newItems,
    };
  });
};

export const orderStockResolution = (order: HasuraOrder, stock: HasuraStock[]) => {
  availableStockReduce = [...stock];

  const newItems = getExtendedItems(order.items);

  return { newItems };
};
