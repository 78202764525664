import * as React from "react";
import { Exclamation } from "heroicons-react";

export enum TypeOfAlert {
  "warning" = "warning",
  "info" = "info",
  "error" = "error",
}

export interface IAlertProps {
  title?: string;
  children: React.ReactNode;
  type?: TypeOfAlert;
  className?: string;
}

type AlertTypeMap = { [key in TypeOfAlert]: string };

const BG_CLASSES: AlertTypeMap = {
  warning: "bg-yellow-100",
  info: "bg-blue-100",
  error: "bg-red-100",
};

const ICON_CLASSES: AlertTypeMap = {
  warning: "text-yellow-500",
  info: "text-blue-500",
  error: "text-red-500",
};

const TITLE_CLASSES: AlertTypeMap = {
  warning: "text-yellow-800",
  info: "text-blue-800",
  error: "text-red-800",
};

const TEXT_CLASSES: AlertTypeMap = {
  warning: "text-yellow-800",
  info: "text-blue-800",
  error: "text-red-800",
};

export function Alert({ title, children, type = TypeOfAlert.warning, className }: IAlertProps) {
  const bgClass = BG_CLASSES[type];
  const iconClass = ICON_CLASSES[type];
  const titleClass = TITLE_CLASSES[type];
  const textClass = TEXT_CLASSES[type];
  return (
    <div className={`rounded-md p-4 ${bgClass} ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Exclamation size={18} className={iconClass} />
        </div>
        <div className="ml-3">
          {title && <h3 className={`mb-2 text-sm leading-5 font-medium ${titleClass}`}>{title}</h3>}
          <div className={`text-sm leading-5 ${textClass}`}>{children}</div>
        </div>
      </div>
    </div>
  );
}
