import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useHasRoles } from "../utils/authContext";

function AdminNavigation() {
  const isAdmin = useHasRoles(["admin"]);
  const match = useRouteMatch();
  return (
    <>
      {isAdmin && (
        <NavLink to={`${match.url}`} exact activeClassName="nav-btn--active" className="nav-btn">
          Ordenes
        </NavLink>
      )}
      {isAdmin && (
        <NavLink to={`${match.url}customers`} exact activeClassName="nav-btn--active" className="nav-btn">
          Clientes
        </NavLink>
      )}
      <NavLink to={`${match.url}production`} exact activeClassName="nav-btn--active" className="nav-btn">
        Producción
      </NavLink>
      <NavLink to={`${match.url}shipping`} exact activeClassName="nav-btn--active" className="nav-btn">
        Despachos
      </NavLink>
      <NavLink to={`${match.url}stock`} exact activeClassName="nav-btn--active" className="nav-btn">
        Inventario
      </NavLink>
      <NavLink to={`${match.url}quality`} exact activeClassName="nav-btn--active" className="nav-btn">
        Calidad
      </NavLink>
      {isAdmin && (
        <NavLink to={`${match.url}settings`} exact activeClassName="nav-btn--active" className="nav-btn">
          Config
        </NavLink>
      )}
    </>
  );
}

export default AdminNavigation;
