import * as React from "react";
import OrderForm from "../../ui/OrderForm";
import { useParams } from "react-router";
import { api } from "../../utils/api";
import Layout from "../../layout/Layout";
import { useQuery } from "@apollo/client";
import { GET_ORDER_BY_ID } from "../../utils/gqlQueries";

export interface IOrderCreateOrEditProps {}

const CreateOrderForUser = ({ userId }: { userId: string }) => {
  const { data: customer } = api.contacts.getById(userId);
  if (!customer) return null;
  return (
    <Layout title={`Crear Orden de Compra: ${customer.name}`}>
      <OrderForm customer={customer} redirectUrl="/" isAdmin />
    </Layout>
  );
};

const EditOrder = ({ orderId }: { orderId: string }) => {
  // const { data: order } = api.orders.getById(orderId);
  const { data } = useQuery<{ orders_by_pk: HasuraOrder }>(GET_ORDER_BY_ID, { variables: { id: orderId } });
  const order = data?.orders_by_pk;
  if (!order) return null;
  return (
    <Layout title={`Editar Orden de Compra: ${order.client.name}`}>
      <OrderForm order={order} customer={order.client} isAdmin />
    </Layout>
  );
};

export function OrderCreateOrEdit() {
  const params = useParams<{ userId?: string; orderId?: string }>();
  if (params.userId) {
    return <CreateOrderForUser userId={params.userId} />;
  }
  if (params.orderId) {
    return <EditOrder orderId={params.orderId} />;
  }
  return (
    <Layout title="Crear Nueva Orden de Compra">
      <OrderForm />
    </Layout>
  );
}

export default OrderCreateOrEdit;
