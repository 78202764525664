import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import guyWorking from "../../assets/guy-working.png";
import Layout from "../../layout/Layout";
import { Alert, TypeOfAlert } from "../../ui/Alert";
import OrderRow from "../../ui/OrderRow";
import { useCustomer } from "../../utils/customerContext";
import { GET_ORDERS_BY_CLIENT } from "../../utils/gqlQueries";

function Orders() {
  const match = useRouteMatch();
  const customer = useCustomer();
  const { loading, data } = useQuery<{ orders: HasuraOrder[] }>(GET_ORDERS_BY_CLIENT, {
    variables: { id: customer?.id },
    fetchPolicy: "no-cache",
  });
  const orders = data?.orders;

  return (
    <Layout title="Tus Ordenes de compra">
      <div className="bg-white shadow sm:rounded-md">
        <ul>
          {orders?.map((order) => {
            return (
              <OrderRow
                showPrice
                showLot
                showCity
                showInvoice
                order={order}
                key={order.id}
                linkResolver={(id) => `${match.url}/order/${id}`}
              />
            );
          })}
        </ul>
      </div>
      {orders?.length === 0 && !loading && (
        <div className="flex items-center flex-col">
          <img src={guyWorking} className="max-w-md w-full mb-4" alt="Persona trbajando" />
          <Alert type={TypeOfAlert.info} title="Bienvenido a Nutti® App!" className="w-auto">
            <div>
              <p>Aún no has creado ninguna orden de compra</p>
            </div>
            <Link to={`${match.url}/new-order`} className="btn mt-3 inline-block">
              Crear Orden De Compra
            </Link>
          </Alert>
        </div>
      )}
    </Layout>
  );
}

export default Orders;
