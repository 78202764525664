import axios from "axios";
import { useQuery } from "react-query";

interface IAggregationsGroup {
  canela: number | undefined;
  maranon: number | undefined;
  cocoa: number | undefined;
  almendra: number | undefined;
  macadamia: number | undefined;
  mani: number | undefined;
  pink: number | undefined;
  matcha: number | undefined;
  golden: number | undefined;
  mani_500: number | undefined;
  matc_500: number | undefined;
  alm_kg: number | undefined;
  mani_kg: number | undefined;
}
export const useStockAggregations = () => {
  return useQuery("stock_aggregations", async () => {
    const res = await axios.post("/.netlify/functions/hasura", {
      type: "run_sql",
      args: {
        sql: "SELECT product, SUM (quantity) AS quantity FROM stock GROUP BY product ORDER BY quantity",
      },
    });

    const prods = res.data.result.reduce(
      (acc: any, curr: any, idx: number) => (idx === 0 ? acc : { ...acc, [curr[0]]: curr[1] }),
      {}
    );
    return prods as IAggregationsGroup;
  });
};

export const useStockAggregationsV2 = () => {
  return useQuery("stock_aggregations_v2", async () => {
    const res = await axios.post("/.netlify/functions/hasura", {
      type: "run_sql",
      args: {
        sql: 'SELECT product, SUM("unusedQty") AS unusedQty FROM stock WHERE "unusedQty" > 0 GROUP BY product ORDER BY unusedQty;',
      },
    });

    const prods = res.data.result.reduce(
      (acc: any, curr: any, idx: number) => (idx === 0 ? acc : { ...acc, [curr[0]]: curr[1] }),
      {}
    );
    return prods as IAggregationsGroup;
  });
};
export default useStockAggregations;
