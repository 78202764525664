import React from "react";
import logo from "../assets/logo.jpeg";
import { User } from "heroicons-react";
import Dropdown from "../ui/Dropdown";
import identity from "netlify-identity-widget";

interface IProps {
  children?: React.ReactNode;
  showAccount?: boolean;
}
function Header({ children, showAccount }: IProps) {
  return (
    <>
      <nav className=" bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-y-auto  sm:overflow-y-visible">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-10 w-10 rounded-full" src={logo} alt="Nutti logo" />
              </div>
              <div className="flex-shrink-0">
                <div className="ml-2 sm:ml-10 flex items-baseline">{children}</div>
              </div>
            </div>
            <div className="hidden md:block">
              {showAccount && (
                <div className="ml-4 flex items-center md:ml-6">
                  <Dropdown Icon={User}>
                    <>
                      <button
                        onClick={() => identity.logout()}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        role="menuitem"
                      >
                        Salir
                      </button>
                    </>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
