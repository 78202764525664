import React from "react";
import { useParams } from "react-router";
import Layout from "../../layout/Layout";
// import { Alert, TypeOfAlert } from "../../ui/Alert";
import OrderForm from "../../ui/OrderForm";
import { useCustomer } from "../../utils/customerContext";

function OrderCreation() {
  const customer = useCustomer();
  const params = useParams<{ token: string; clientSlug: string }>();

  return (
    <Layout title="Crear Nueva Orden de Compra">
      {/* <div>
        <Alert type={TypeOfAlert.info} title="Estámos cerrados por temporada de vacaciones.">
          ¡Hola! Recibirémos nuevamente órdenes de compra a partir del <strong>14 de enero.</strong>
        </Alert>
      </div> */}
      <OrderForm customer={customer} redirectUrl={`/clients/${params.clientSlug}/${params.token}`} />
    </Layout>
  );
}

export default OrderCreation;
