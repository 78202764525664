import { useMutation } from "@apollo/client";
import cogoToast from "cogo-toast";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import Modal from "../../ui/Modal";
import { REMOVED_PRODUCTS } from "../../utils/constants";
import { CREATE_STOCK_MUTATION, EDIT_STOCK_MUTATION } from "../../utils/gqlQueries";
import { PRODUCTS_MAPPER } from "../../utils/prodsUtils";
import { DATE_FORMAT_STOCK, lotIdToFormat } from "../../utils/stocking";
import { useHasRoles } from "../../utils/authContext";
dayjs.extend(customParseFormat);

interface IStockEntryModal {
  onCreated?: () => void;
  onCancel?: () => void;
  open: boolean;
  setOpen?: (open: boolean) => void;
  entry?: HasuraStock | null;
}

function StockEntryModal({ onCreated, open, setOpen, entry, onCancel }: IStockEntryModal) {
  const [entryProduct, setEntryProduct] = useState("");
  const [entryQty, setEntryQty] = useState("");
  const [entryLotId, setEntryLotId] = useState(dayjs().format("DDMMYY"));
  const [entryExpDate, setEntryExpDate] = useState(dayjs().add(1, "year").format("YYYY-MM-DD"));
  const [addStockMutation] = useMutation(CREATE_STOCK_MUTATION);
  const [editStockMutation] = useMutation(EDIT_STOCK_MUTATION);
  const isAdmin = useHasRoles(["admin"]);
  useEffect(() => {
    if (entry) {
      const { product, quantity, lotId, expDate } = entry;
      setEntryProduct(product);
      setEntryQty(quantity.toString());
      setEntryLotId(lotIdToFormat(lotId));
      setEntryExpDate(expDate);
    }
  }, [entry]);

  const handleEntryModal = async () => {
    if (entryProduct && entryQty) {
      if (entry) {
        const usedQty = entry.history?.reduce((acc, curr) => acc + curr.taken, 0) || 0;
        const newUnusedQty = Number(entryQty) - usedQty;

        // Editing entry
        if (newUnusedQty && newUnusedQty < 0) {
          cogoToast.error(`La nueva cantidad no puede ser menor a la ya usada. (${usedQty})`);
          return;
        }
        await editStockMutation({
          variables: {
            id: entry.id,
            data: {
              product: entryProduct,
              quantity: entryQty,
              unusedQty: newUnusedQty,
              lotId: dayjs(entryLotId, "DDMMYY").format(DATE_FORMAT_STOCK),
              expDate: dayjs(entryExpDate).format(DATE_FORMAT_STOCK),
            },
          },
        });
      } else {
        // Creating entry
        await addStockMutation({
          variables: {
            data: {
              product: entryProduct,
              quantity: entryQty,
              unusedQty: entryQty,
              date: dayjs().format(DATE_FORMAT_STOCK),
              creator: "manual",
              lotId: dayjs(entryLotId, "DDMMYY").format(DATE_FORMAT_STOCK),
              expDate: dayjs(entryExpDate).format(DATE_FORMAT_STOCK),
            },
          },
        });
      }
      if (onCreated) onCreated();
    }
  };

  return (
    <Modal
      open={open}
      onAccept={handleEntryModal}
      onCancel={() => {
        if (setOpen) setOpen(false);
        if (onCancel) onCancel();
      }}
    >
      <>
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Agregar Inventario
        </h3>
        <div className="border-t border-gray-100 my-5"></div>
        <form>
          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="product">
            Producto
          </label>
          <select
            id="product"
            value={entryProduct}
            onChange={(e) => setEntryProduct(e.target.value)}
            className="border w-full py-1 px-2 rounded text-sm"
          >
            <option value="">Selecciona uno</option>
            {PRODUCTS_MAPPER.filter((p) => isAdmin || !REMOVED_PRODUCTS.includes(p.id)).map((prod) => (
              <option value={prod.slug} key={prod.slug}>
                {prod.return}
              </option>
            ))}
          </select>

          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="qty">
            Cantidad
          </label>
          <input
            id="qty"
            value={entryQty}
            onChange={(e) => setEntryQty(e.target.value)}
            inputMode="numeric"
            className="border w-full py-1 px-2 rounded text-sm"
            type="number"
            placeholder="Cantidad"
          />

          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="lot">
            Lote
          </label>
          <input
            id="lot"
            value={entryLotId}
            onChange={(e) => setEntryLotId(e.target.value)}
            inputMode="numeric"
            className="border w-full py-1 px-2 rounded text-sm"
            type="number"
            placeholder="Lote"
          />

          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="expirationDate">
            Fecha Vencimiento
          </label>
          <input
            id="expirationDate"
            value={entryExpDate}
            onChange={(e) => setEntryExpDate(e.target.value)}
            className="border w-full py-1 px-2 rounded text-sm"
            type="date"
          />
        </form>
      </>
    </Modal>
  );
}

export default StockEntryModal;
