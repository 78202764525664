import { Placement } from "@popperjs/core";
import { DotsVertical } from "heroicons-react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";

interface Dropdown {
  children?: React.ReactNode;
  Icon?: React.ReactType;
  position?: Placement;
}

const Dropdown = ({ children, Icon = DotsVertical, position }: Dropdown) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position || "bottom-end",
  });
  const [open, setOpen] = useState(false);
  useClickAway({ current: popperElement }, () => {
    setOpen(false);
  });

  const elemPortal = document.getElementById("popperPortal");

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600"
          aria-label="Options"
          id="options-menu"
          aria-haspopup="true"
          // aria-expanded={open}
          onClick={(e) => setOpen(!open)}
          ref={setReferenceElement as any}
        >
          <Icon size={20} className="text-gray-600" />
        </button>
      </div>
      {elemPortal &&
        open &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement as any}
            // ref={ref}
            style={styles.popper}
            {...attributes.popper}
            // className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10 "
          >
            <div className="rounded-md bg-white shadow-xs">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {children}
              </div>
            </div>
          </div>,
          elemPortal
        )}
    </div>
  );
};
export default Dropdown;
