import React from "react";
import ReactDOM from "react-dom";

interface IModalProps {
  open?: boolean;
  cancelText?: string;
  okText?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccept?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Modal = ({
  open,
  cancelText = "Cancelar",
  okText = "Aceptar",
  children,
  onCancel,
  onAccept,
  disabled = false,
}: IModalProps) => {
  if (!open) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>{children}</div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button
                disabled={disabled}
                type="button"
                className="btn-base btn w-full justify-center"
                onClick={onAccept}
              >
                {okText}
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
              <button
                disabled={disabled}
                type="button"
                className="btn-base btn-secondary w-full justify-center"
                onClick={onCancel}
              >
                {cancelText}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modalsPortal") as HTMLElement
  );
};

export default Modal;
