import React from "react";
import { useParams } from "react-router";
import Layout from "../../layout/Layout";
import { Alert, TypeOfAlert } from "../../ui/Alert";
import { api } from "../../utils/api";
import OrderRow, { ORDER_ROW_PRESETS } from "../../ui/OrderRow";
import OrderContextMenu from "../../ui/OrderContextMenu";
import { useQuery } from "@apollo/client";
import { GET_ORDERS_BY_CLIENT } from "../../utils/gqlQueries";

const Orders = ({ id }: { id: string }) => {
  const { data, refetch } = useQuery<{ orders: HasuraOrder[] }>(GET_ORDERS_BY_CLIENT, { variables: { id } });
  const orders = data?.orders;

  if (!orders) return null;
  if (orders.length === 0)
    return (
      <Alert type={TypeOfAlert.info} title="No hay ordenes">
        No tenemos ordenes de este cliente
      </Alert>
    );
  return (
    <div className="bg-white shadow sm:rounded-md">
      <ul>
        {orders.map((order) => (
          <OrderRow
            key={order.id}
            order={order}
            linkResolver={(id) => `/orders/${id}`}
            contextMenu={<OrderContextMenu order={order} refetchOrders={refetch} />}
            {...ORDER_ROW_PRESETS.ADMIN}
          />
        ))}
      </ul>
    </div>
  );
};

const CustomerDetails = () => {
  const matchParams = useParams<{ id: string }>();
  const { data: contact } = api.contacts.getById(matchParams.id);

  if (!contact) return <div>Loading...</div>;

  return (
    <Layout title={contact.name}>
      <Orders id={matchParams.id} />
    </Layout>
  );
};

export default CustomerDetails;
