import React from "react";
import { ReactQueryDevtools } from "react-query-devtools";
import { Route, RouteProps, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./layout/Header";
import AdminNavigation from "./layout/adminNavigation";
import ClientNavigation from "./layout/clientNavigation";
import CustomerDetails from "./pages/admin/CustomerDetails";
import Customers from "./pages/admin/Customers";
import OrderCreateOrEdit from "./pages/admin/OrderCreateOrEdit";
import OrderDetails from "./pages/admin/OrderDetails";
import OrderDetailsQty from "./pages/admin/OrderDetailsQuality";
import Orders from "./pages/admin/Orders";
import Production from "./pages/admin/Production";
import Quality from "./pages/admin/Quality";
import Settings from "./pages/admin/Settings";
import Shipping from "./pages/admin/Shipping";
import StockV2 from "./pages/admin/StockV2";
import ClientsOrders from "./pages/client/ClientsOrders";
import OderCreation from "./pages/client/OrderCreation";
import { ApolloAdminProvider, ApolloCustomerProvider } from "./utils/apolloClientProvider";
import { AuthProvider, useHasRoles } from "./utils/authContext";
import { CustomerProvider } from "./utils/customerContext";

interface IPrivateRouteProps extends RouteProps {
  roles: string[];
}

const PrivateRoute = ({ children, roles, ...rest }: IPrivateRouteProps) => {
  const hasRoles = useHasRoles(roles);
  if (hasRoles) {
    return <Route {...rest}>{children}</Route>;
  } else {
    return <div>Sneaky</div>;
  }
};

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/clients/:clientSlug/:token">
            <CustomerProvider>
              <ApolloCustomerProvider>
                <div>
                  <Header>
                    <ClientNavigation />
                  </Header>
                  <Route path="/clients/:clientSlug/:token/new-order">
                    <OderCreation />
                  </Route>
                  <Route path="/clients/:clientSlug/:token/order/:id">
                    <OrderDetails />
                  </Route>
                  <Route exact path="/clients/:clientSlug/:token">
                    <ClientsOrders />
                  </Route>
                </div>
              </ApolloCustomerProvider>
            </CustomerProvider>
          </Route>
          <Route path="/">
            <AuthProvider>
              <ApolloAdminProvider>
                <div>
                  <Header showAccount={true}>
                    <AdminNavigation />
                  </Header>
                  <Switch>
                    <PrivateRoute roles={["admin"]} path="/customers" exact>
                      <Customers />
                    </PrivateRoute>
                    <PrivateRoute roles={["admin"]} path="/customers/:id" exact>
                      <CustomerDetails />
                    </PrivateRoute>
                    <PrivateRoute roles={["admin"]} path="/" exact>
                      <Orders />
                    </PrivateRoute>
                    <Route path="/orders/create/:userId?" exact>
                      <OrderCreateOrEdit />
                    </Route>
                    <Route path="/orders/edit/:orderId?" exact>
                      <OrderCreateOrEdit />
                    </Route>
                    <Route path="/orders/:id" exact>
                      <OrderDetails />
                    </Route>
                    <Route path="/orders-read/:id" exact>
                      <OrderDetailsQty />
                    </Route>
                    <Route path="/production">
                      <Production />
                    </Route>
                    <Route path="/stock">
                      <StockV2 />
                    </Route>
                    <Route path="/quality">
                      <Quality />
                    </Route>
                    <Route path="/shipping">
                      <Shipping />
                    </Route>
                    <PrivateRoute roles={["admin"]} path="/settings">
                      <Settings />
                    </PrivateRoute>
                  </Switch>
                </div>
              </ApolloAdminProvider>
            </AuthProvider>
          </Route>
        </Switch>
      </Router>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
