import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Layout from "../../layout/Layout";
import OrderQualityMenu from "../../ui/OrderQualityMenu";
import OrderRow from "../../ui/OrderRow";
import { OrderFields } from "../../utils/gqlQueries";
import { DATE_FORMAT_STOCK, LOT_FORMAT } from "../../utils/stocking";

const PER_PAGE = 30;

const QA_ORDERS = gql`
  query QaQuery($where: orders_bool_exp!, $offset: Int = 0) {
    orders_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    orders(where: $where, limit: 30, offset: $offset order_by: { created_at: desc }) {
      ${OrderFields}
    }
  }
`;

const baseParams = { status: { _eq: "Finalizado" } };

function Quality() {
  const [offset, setOffset] = useState<number>(0);
  const { data, refetch } = useQuery<HasuraOrderResponse>(QA_ORDERS, {
    variables: { where: baseParams },
  });
  const orders = data?.orders;
  const count = data?.orders_aggregate.aggregate.count || 0;

  const pages = Math.ceil(count / 30);
  const fetchData = useCallback(() => {
    const client = (document.getElementById("searchClient") as HTMLInputElement)?.value;
    const lot = (document.getElementById("searchLot") as HTMLInputElement)?.value;
    const lotv2 = (document.getElementById("searchLotv2") as HTMLInputElement)?.value;
    const filterParams = {
      ...(client ? { clientName: { _ilike: `%${client}%` } } : {}),
      ...(lot ? { lot: { _eq: lot } } : {}),
      ...(lotv2
        ? {
            lots: {
              _cast: {
                String: {
                  _ilike: `%${dayjs(lotv2, LOT_FORMAT).format(DATE_FORMAT_STOCK)}%`,
                },
              },
            },
          }
        : {}),
    };
    refetch({ where: { ...baseParams, ...filterParams }, offset });
  }, [offset, refetch]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout title="Calidad">
      <form onSubmit={handleSubmit}>
        <div className="flex sm:flex-row flex-col sm:items-end mb-4">
          <div className="flex flex-col mr-2">
            <label htmlFor="searchClient" className="text-xs">
              Cliente
            </label>
            <input type="text" id="searchClient" className="border rounded p-1 text-sm" />
          </div>
          <div className="flex flex-col mr-2">
            <label htmlFor="searchLot" className="text-xs">
              Lote
            </label>
            <input type="text" id="searchLot" className="border rounded p-1 text-sm" />
          </div>
          <div className="flex flex-col mr-2">
            <label htmlFor="searchLotv2" className="text-xs">
              Lote V2
            </label>
            <input type="text" id="searchLotv2" className="border rounded p-1 text-sm" />
          </div>
          {/* <div className="flex flex-col mr-2">
            <label htmlFor="date" className="text-xs">
              Fecha finalizada
            </label>
            <input type="text" id="date" className="border rounded p-1 text-sm" />
          </div> */}

          <button type="submit" className="btn mt-2 sm:mt-0">
            Buscar
          </button>
        </div>
      </form>

      <div className="bg-white shadow sm:rounded-md">
        <ul>
          {orders?.map((order) => {
            return (
              <OrderRow
                order={order}
                key={order.id}
                showLot
                showTotalQty
                showInvoice
                showCity
                date={<div>{(order.statusDates as any)?.Finalizado}</div>}
                contextMenu={<OrderQualityMenu order={order} refetchOrders={refetch} />}
                linkResolver={(id) => `/orders-read/${id}`}
              />
            );
          })}
        </ul>
      </div>
      <div className="overflow-x-auto pb-2">
        <ReactPaginate
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({ selected }) => {
            setOffset(Math.ceil(selected * PER_PAGE));
          }}
          containerClassName={"pagination"}
        />
      </div>
    </Layout>
  );
}

export default Quality;
