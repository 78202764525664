import React from "react";
import PageHeader from "./PageHeader";

interface IProps {
  children: React.ReactNode;
  middle?: React.ReactNode;
  title?: string;
  full?: boolean;
}

function Layout({ children, title, full, middle }: IProps) {
  const widthClassName = full ? "w-full" : "max-w-6xl";
  return (
    <main>
      {title && <PageHeader title={title} middle={middle} />}
      <div className="px-3 py-6 sm:px-0">
        <div className={`${widthClassName} mx-auto sm:px-4`}>{children}</div>
      </div>
    </main>
  );
}

export default Layout;
