import * as React from "react";
import { HasuraOrderStatus, FaunaPaymentStatus } from "../enums";
import { CurrencyDollarOutline } from "heroicons-react";

const BADEGS: { [key in HasuraOrderStatus]: string } = {
  Creado: "bg-red-100 text-red-800 border border-red-400",
  "En Produccion": "bg-yellow-100 text-yellow-800 border border-yellow-400",
  Enviado: "bg-blue-100 text-blue-800 border border-blue-400",
  Despacho: "bg-teal-100 text-teal-800 border border-teal-400",
  Finalizado: "bg-green-100 text-green-800 border border-green-400",
  Cancelado: "bg-gray-100 text-gray-600 border-gray-300",
};

export function OrderStatusBadge({ status, className }: { status: HasuraOrderStatus; className?: string }) {
  return <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize ${BADEGS[status]} ${className}`}>{status}</span>;
}

export const isOrderPaid = (order: HasuraOrder) => order.payment === FaunaPaymentStatus.Pagado;

export function OrderPaymentBadge({ payment }: { payment: FaunaPaymentStatus }) {
  if (payment === FaunaPaymentStatus.Pagar) {
    // return (
    //   <span className="px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize bg-red-100 text-red-400">
    //     <span>Por pagar</span>
    //   </span>
    // );
    return <CurrencyDollarOutline size={20} className="text-red-300 mr-1" />;
  } else {
    return <CurrencyDollarOutline size={20} className="text-green-300 mr-1" />;
  }
}
