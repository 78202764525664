import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { Alert, TypeOfAlert } from "../../ui/Alert";
import { ExpirationBadge, LotBadge } from "../../ui/OrderRow";
import { GET_ORDER } from "../../utils/gqlQueries";
import OrderDetailStocks from "../../ui/OrderDetailStocks";

function OrderDetailsQuality() {
  const params = useParams<{ id: string }>();

  const { data } = useQuery<{ orders_by_pk: HasuraOrder }>(GET_ORDER, { variables: { id: params.id } });
  const order = data?.orders_by_pk;

  if (!order) return null;
  return (
    <Layout title={order.client?.name}>
      {order.comments && (
        <Alert title="Sede" type={TypeOfAlert.info} className="mb-4">
          <p>{order.comments}</p>
        </Alert>
      )}
      <div className="flex">
        {order.expiration && <ExpirationBadge order={order} />}
        {order.lot && <LotBadge order={order} />}
      </div>
      <div>
        <div>
          <div className="hidden sm:block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <div className="px-4 py-4 flex items-center sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between text-sm text-gray-600">
                <div className="pr-2 w-full sm:w-2/6">Producto</div>
                <div className="pr-2 mt-4 w-1/6 flex-shrink-0 sm:mt-0 text-right">Cantidad</div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow sm:rounded-md">
            <ul>
              {order?.items?.map((item, idx: number) => {
                return (
                  <li key={idx}>
                    <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out border-b border-gray-200">
                      <div className="px-4 py-4 flex items-center sm:px-6">
                        <div className="pr-2">
                          <div className="text-sm leading-5 font-medium">{item.name}</div>
                        </div>
                        <div className="w-full min-w-0 flex-1 flex sm:items-center justify-between">
                          <div className="pr-2 mt-4 flex-grow flex-shrink-0 sm:mt-0 text-right">
                            <div className="uppercase text-xs text-gray-600 sm:hidden">CANT.</div>
                            <span className="font-mono text-sm">{item.qty}</span>
                          </div>
                        </div>
                      </div>
                      <OrderDetailStocks order={order} item={item} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <div className="p-4 flex items-center border-t">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="w-4/12"></div>
                <div className="mt-4 pr-2 sm:w-1/6 sm:mt-0 font-mono flex justify-end">
                  <span className="sm:hidden text-sm text-gray-600 mr-5 flex-grow">Total Items</span>
                  {order.totalQty}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {order.notes && (
        <Alert title="Notas" type={TypeOfAlert.info} className="mb-4">
          <p>{order.notes}</p>
        </Alert>
      )}
    </Layout>
  );
}

export default OrderDetailsQuality;
