import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Modal from "../../ui/Modal";
import { UPDATE_STOCK_MULTIPLE_MUTATION } from "../../utils/gqlQueries";

interface IStockGiftingModal {
  onCreated?: () => void;
  onCancel?: () => void;
  setOpen?: (open: boolean) => void;
  open: boolean;
  entry: HasuraStock | null;
}

function StockGiftingModal({ onCreated, open, setOpen, entry, onCancel }: IStockGiftingModal) {
  const [qty, setQty] = useState("");
  const [reason, setReason] = useState("Regalo");
  const [updateStockHistoryMutation] = useMutation(UPDATE_STOCK_MULTIPLE_MUTATION);
  const [loading, setLoading] = useState(false);

  const handleEntryModal = async () => {
    const qtyNum = Number(qty);
    setLoading(true);
    if (qtyNum && entry) {
      await updateStockHistoryMutation({
        variables: {
          id: entry.id,
          data: {
            unusedQty: entry.unusedQty - qtyNum,
          },
          history: {
            reason: reason,
            taken: qtyNum,
            date: new Date(),
          },
        },
      });
      setLoading(false);
      if (onCreated) onCreated();
    }
  };

  return (
    <Modal
      disabled={loading}
      open={open}
      onAccept={handleEntryModal}
      onCancel={() => {
        if (setOpen) setOpen(false);
        if (onCancel) onCancel();
      }}
    >
      <>
        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          Extras
        </h3>
        <div className="border-t border-gray-100 my-5"></div>
        <form>
          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="qty">
            Cantidad
          </label>
          <input
            id="qty"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            inputMode="numeric"
            className="border w-full py-1 px-2 rounded text-sm"
            type="number"
            placeholder="Cantidad"
          />

          <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="qty">
            Razón
          </label>
          <input
            id="expirationDate"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="border w-full py-1 px-2 rounded text-sm"
            type="text"
          />
        </form>
      </>
    </Modal>
  );
}

export default StockGiftingModal;
