export enum HasuraOrderStatus {
  "Creado" = "Creado",
  "Produccion" = "En Produccion",
  "Despacho" = "Despacho",
  "Enviado" = "Enviado",
  "Finalizado" = "Finalizado",
  "Cancelado" = "Cancelado",
}

export enum FaunaPaymentStatus {
  "Pagado" = "Pagado",
  "Pagar" = "Por Pagar",
}

export enum ProductHandles {
  macadamia = "macadamia",
  mani = "mani",
}
