import React from "react";
import { HasuraOrderStatus } from "../../enums";
import Layout from "../../layout/Layout";
import StatusTable from "../../ui/StatusTable";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_MUTATION } from "../../utils/gqlQueries";

function Production() {
  const [updateOrderMutation] = useMutation(UPDATE_ORDER_MUTATION);

  const handleCheckChange = async (order: HasuraOrder, e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    try {
      await updateOrderMutation({
        variables: { id: order.id, data: { shippingReady: checked } },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout title="Envios" full>
      <StatusTable
        status={HasuraOrderStatus.Despacho}
        onCheckboxChange={handleCheckChange}
        checkboxKey="shippingReady"
        view="shipping"
      />
    </Layout>
  );
}

export default Production;
