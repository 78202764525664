import React from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { useCustomer } from "./customerContext";

const URI = "/.netlify/functions/hasura";

const adminClient = new ApolloClient({
  uri: URI,
  cache: new InMemoryCache({
    typePolicies: {
      orders: {
        fields: {
          lots: {
            //cache is stupid and gives warning if this is not here, telling it to YES replace with empty array
            merge(existing = [], incoming: any[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export const ApolloCustomerProvider = ({ children }: { children: React.ReactNode }) => {
  const customer = useCustomer();
  if (!customer) return null;
  const customerClient = new ApolloClient({
    uri: URI,
    headers: {
      "x-hasura-role": "customer",
      "x-hasura-user-id": customer.id,
    },
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={customerClient}>{children}</ApolloProvider>;
};

export const ApolloAdminProvider = ({ children }: { children: React.ReactNode }) => {
  return <ApolloProvider client={adminClient}>{children}</ApolloProvider>;
};
