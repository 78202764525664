interface IProductMapper {
  match: string;
  return: string;
  slug: string;
  weight: number;
  id: number;
}

export const PRODUCTS_MAPPER: IProductMapper[] = [
  {
    match: "Flexible Crema de Almendra 100g",
    return: "Fl-Almendra",
    slug: "fl-almendra",
    weight: 100,
    id: 18,
  },
  {
    match: "Flexible Crema de Maní 100g",
    return: "Fl-Maní",
    slug: "fl-mani",
    weight: 100,
    id: 19,
  },
  {
    match: "Flexible Crema de Macadamia 100g",
    return: "Fl-Macadamia",
    slug: "fl-macadamia",
    weight: 100,
    id: 17,
  },
  {
    match: "Flexible Crema de Macadamia Pinkfud 100g",
    return: "Fl-Pinkfud",
    slug: "fl-pinkfud",
    weight: 100,
    id: 24,
  },
  {
    match: "Flexible Crema de Marañón Canela 100g",
    return: "Fl-Canela",
    slug: "fl-canela",
    weight: 100,
    id: 23,
  },
  {
    match: "Flexible Crema de Marañón Cocoa 100g",
    return: "Fl-Cocoa",
    slug: "fl-cocoa",
    weight: 100,
    id: 22,
  },
  {
    match: "Flexible Crema de Marañón 100g",
    return: "Fl-Marañón",
    slug: "fl-maranon",
    weight: 100,
    id: 16,
  },
  {
    match: "Flexible Crema de Pistacho 100g",
    return: "Fl-Pistacho",
    slug: "fl-pistacho",
    weight: 100,
    id: 25,
  },
  {
    match: "Crema de Marañón Canela 240g",
    return: "Canela",
    slug: "canela",
    weight: 230,
    id: 13,
  },
  {
    match: "Crema de Marañón 240g",
    return: "Marañón",
    slug: "maranon",
    weight: 240,
    id: 3,
  },
  {
    match: "Crema de Marañón Cocoa 240g",
    return: "Cocoa",
    slug: "cocoa",
    weight: 240,
    id: 5,
  },
  {
    match: "Crema de Almendras 230g",
    return: "Almendra",
    slug: "almendra",
    weight: 230,
    id: 1,
  },
  {
    match: "Crema de Macadamia 230g",
    return: "Macadamia",
    slug: "macadamia",
    weight: 230,
    id: 2,
  },
  {
    match: "Crema de Maní 240g",
    return: "Maní",
    slug: "mani",
    weight: 240,
    id: 4,
  },
  {
    match: "Crema de Macadamia Pinkfud 100g",
    return: "Pink",
    slug: "pink",
    weight: 102,
    id: 6,
  },
  {
    match: "Crema de Macadamia Matchachá 100g",
    return: "Matcha",
    slug: "matcha",
    weight: 102,
    id: 7,
  },
  {
    match: "Crema de Macadamia Golden Mix 100g",
    return: "Golden",
    slug: "golden",
    weight: 102,
    id: 8,
  },
  {
    match: "Crema de Maní 500g",
    return: "Maní 500",
    slug: "mani_500",
    weight: 500,
    id: 9,
  },
  {
    match: "Crema de Macadamia Matchachá 500g",
    return: "Matc 500",
    slug: "matc_500",
    weight: 500,
    id: 10,
  },
  {
    match: "Crema de Almendras 1kg",
    return: "Alm kg",
    slug: "alm_kg",
    weight: 1000,
    id: 11,
  },
  {
    match: "Crema de Maní 1Kg",
    return: "Maní kg",
    slug: "mani_kg",
    weight: 1000,
    id: 12,
  },
  {
    match: "Crema de Nueces 32g",
    return: "Nueces 32",
    slug: "nueces_32",
    weight: 32,
    id: 14,
  },
  {
    match: "Crema de Pistacho 1Kg",
    return: "Pistacho kg",
    slug: "pistacho_kg",
    weight: 1000,
    id: 26,
  },
];

export const findMapperMatch = (prod: string): IProductMapper | undefined => {
  const found = PRODUCTS_MAPPER.find((i) => i.match === prod);
  return found;
};

export const findNameMatch = (prod: string): string => {
  const found = PRODUCTS_MAPPER.find((i) => i.match === prod);
  return found ? found.return : prod;
};

export const findWeightMatch = (prod: string): number => {
  const found = PRODUCTS_MAPPER.find((i) => i.match === prod);
  return found ? found.weight : 1;
};
