import * as React from "react";
import { api } from "../../utils/api";
import Layout from "../../layout/Layout";
import Toggle from "react-toggle";
import { AlegraItem } from "alegra-node-api";
import { Form, FormRenderProps, Field } from "react-final-form";
import cogoToast from "cogo-toast";

interface IFormLayoutProps extends Partial<FormRenderProps> {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}
const FormLayout = ({ children, handleSubmit }: IFormLayoutProps) => {
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Productos</h3>
          <p className="mt-1 text-sm leading-5 text-gray-600">Que productos estan disponibles, con poco stock</p>
        </div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white sm:p-6">{children}</div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <span className="inline-flex rounded-md shadow-sm">
                <button type="submit" className="btn">
                  Guardar
                </button>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const ProductSettings = ({ products, settings }: { products: AlegraItem[]; settings: any }) => {
  const [updateSettings] = api.settings.update();
  const onSubmit = async (values: FaunaSettingsProducts) => {
    try {
      await updateSettings({ products: values });
      cogoToast.success("Guardado exitosamente");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={settings.data.products}
      render={({ handleSubmit }) => {
        return (
          <FormLayout title="Productos" subtitle="Que productos estan disponibles, con poco stock" handleSubmit={handleSubmit}>
            <div className="flex px-2 py-4 ">
              <div className="w-6/12 font-medium text-xs text-gray-700 uppercase tracking-wider">Product</div>
              <div className="w-3/12 font-medium text-xs text-gray-700 uppercase tracking-wider">Disponible</div>
              <div className="w-3/12 font-medium text-xs text-gray-700 uppercase tracking-wider">Bajo Stock</div>
            </div>
            {products.map((product, key) => (
              <div key={key} className="flex px-2 py-4 border-t">
                <div className="w-6/12 flex text-sm">{product.name}</div>
                <div className="w-3/12 flex text-sm">
                  <Field
                    name={`product-available-${product.id}`}
                    render={({ input }) => (
                      <Toggle
                        icons={false}
                        className="toggle-sm"
                        defaultChecked={input.value}
                        onChange={(e) => input.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
                <div className="w-3/12 flex text-sm">
                  <Field
                    name={`product-low-${product.id}`}
                    render={({ input }) => (
                      <Toggle
                        icons={false}
                        className="toggle-warn toggle-sm"
                        defaultChecked={input.value}
                        onChange={(e) => input.onChange(e.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>
            ))}
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </FormLayout>
        );
      }}
    ></Form>
  );
};

export function Settings() {
  const { data: settings } = api.settings.get();
  const { data: products } = api.products.get();

  if (!products || !settings) return <div>Cargando...</div>;
  return (
    <div>
      <Layout title="Configuraciones">
        <ProductSettings products={products} settings={settings} />
      </Layout>
    </div>
  );
}

export default Settings;
