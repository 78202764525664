import { useQuery } from "@apollo/client";
import React from "react";
import { REMOVED_PRODUCTS } from "../../utils/constants";
import { GET_AVAILABLE_STOCK } from "../../utils/gqlQueries";
import { PRODUCTS_MAPPER } from "../../utils/prodsUtils";
import { useStockAggregationsV2 } from "../../ui/useStockAggregations";
import { lotIdToFormat } from "../../utils/stocking";

function StockAggregations() {
  const { data } = useStockAggregationsV2();
  const { data: lotsData } = useQuery<HasuraStockResponse>(GET_AVAILABLE_STOCK, {
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <div className="shadow rounded">
        <ul>
          {PRODUCTS_MAPPER.filter((p) => !REMOVED_PRODUCTS.includes(p.id)).map((prod) => (
            <li key={prod.slug} className="border-t border-gray-200 p-2">
              <div className="flex justify-between">
                <span>{prod.return}</span>
                <span>{(data || ({} as any))[prod.slug]}</span>
              </div>
              <div className=" grid grid-cols-3 font-mono">
                {lotsData?.stock
                  ?.filter((lot) => lot.product === prod.slug)
                  .map((lot) => (
                    <div key={lot.id} className="text-xs">
                      {lotIdToFormat(lot.lotId)} - {lot.unusedQty}
                      <span className="text-gray-500">/{lot.quantity}</span>
                    </div>
                  ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default StockAggregations;
