import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useCustomer } from "../utils/customerContext";

function ClientNavigation() {
  const customer = useCustomer();
  const match = useRouteMatch();
  return (
    <>
      <NavLink to={`${match.url}`} exact activeClassName="nav-btn--active" className="nav-btn">
        Ordenes
      </NavLink>
      <NavLink to={`${match.url}/new-order`} exact activeClassName="nav-btn--active" className="nav-btn">
        Crear Orden
      </NavLink>
      <h1 className="text-sm text-gray-600 pl-4 border-l">{customer?.name}</h1>
    </>
  );
}

export default ClientNavigation;
