import { useQuery } from "@apollo/client";
import React from "react";
import { useQuery as useReactQuery } from "react-query";
import { GET_AVAILABLE_STOCK } from "../utils/gqlQueries";
import { lotIdToFormat, mapOrdersWithStock } from "../utils/stocking";

interface IStatusTableForecastProps {
  item: HasuraOrderItem;
  order: HasuraOrder;
  orders: HasuraOrder[];
}

function StatusTableForecast({ item, order, orders }: IStatusTableForecastProps) {
  const { data: availableStockData } = useQuery<HasuraStockResponse>(GET_AVAILABLE_STOCK);
  const { data: forecastStockedOrders } = useReactQuery({
    queryKey: ["ordersForecast", availableStockData?.stock.length],
    queryFn: async () => await mapOrdersWithStock([...orders].reverse(), availableStockData?.stock),
  });

  const forecastItem = forecastStockedOrders?.find((o) => o.id === order.id)?.items.find((i) => i.id === item.id);

  if (!forecastItem) return null;

  return (
    <>
      <div className="space-y-1">
        {forecastItem.usedLots.map((lot, idx) => (
          <div key={idx} className="badge bg-gray-200" style={{ display: "block", width: "fit-content" }}>
            #{lotIdToFormat(lot.lotId)} {lot.taken}
            <span className="text-gray-600">/{lot.quantity}</span>
          </div>
        ))}
      </div>
      {forecastItem.missingUnits > 0 && <div className="badge bg-red-200">{forecastItem.missingUnits}</div>}
    </>
  );
}

export default StatusTableForecast;
