import { intersection } from "lodash";
import identity, { User } from "netlify-identity-widget";
import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/logo.jpeg";

identity.init();

const Login = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <img className="h-10 w-10 rounded-full mb-5" src={logo} alt="Nutti logo" />
      <button onClick={() => identity.open()} className="bg-indigo-600 text-indigo-100 p-2 rounded">
        Ingresar
      </button>
    </div>
  );
};

export const useHasRoles = (roles: string[]) => {
  const user = useAuth();
  return intersection(user?.app_metadata.roles, roles).length > 0;
};

export const AuthContext = React.createContext<User | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(identity.currentUser());

  useEffect(() => {
    identity.on("init", (user) => setUser(user));
    identity.on("login", (user) => setUser(user));
    identity.on("logout", () => setUser(null));
  }, []);

  // useEffect(() => {
  //   const configOneSignal = async () => {
  //     const isEnabled = await window.OneSignal.isPushNotificationsEnabled();
  //     if (!isEnabled && isAdmin(user)) {
  //       const registration = await window.OneSignal.registerForPushNotifications();
  //       console.log(registration);
  //     }
  //   };
  //   if (user) configOneSignal();
  // }, [user]);

  if (!user) return <Login />;
  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);
