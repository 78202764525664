import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ExclamationCircle, ExclamationCircleOutline, InformationCircleOutline } from "heroicons-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../layout/Layout";
import Dropdown from "../../ui/Dropdown";
import useStockAggregations from "../../ui/useStockAggregations";
import { REMOVED_PRODUCTS } from "../../utils/constants";
import { GET_STOCK_V2_WHERE, UPDATE_STOCK_HISTORY } from "../../utils/gqlQueries";
import { PRODUCTS_MAPPER } from "../../utils/prodsUtils";
import { DATE_FORMAT_STOCK, lotIdToFormat } from "../../utils/stocking";
import StockAggregations from "./StockAggregations";
import StockEntryModal from "./StockEntryModal";
import StockGiftingModal from "./StockGiftingModal";
dayjs.extend(customParseFormat);

function Stock() {
  const [newEntryModalOpen, setNewEntryModalOpen] = useState(false);
  const [editEntryModalOpen, setEditEntryModalOpen] = useState(false);
  const [editingEntry, setEditingEntry] = useState<HasuraStock | null>(null);
  const [giftingModalOpen, setGiftingModalOpen] = useState(false);
  const [giftingEntry, setGiftingEntry] = useState<HasuraStock | null>(null);
  const [updateStockHistoryMutation] = useMutation(UPDATE_STOCK_HISTORY);

  const isDebug = window.location.search.includes("debug");

  const [searchDates, setSearchDates] = useState({
    from: dayjs().subtract(6, "week").format(DATE_FORMAT_STOCK),
    to: dayjs().add(1, "day").format(DATE_FORMAT_STOCK),
    product: "",
  });
  const { refetch: refetchAggregations } = useStockAggregations();
  const { data: stockData, refetch: refetchStockEntries } = useQuery<HasuraStockResponse>(GET_STOCK_V2_WHERE, {
    variables: {
      where: {
        unusedQty: { _is_null: false },
        date: {
          _gte: searchDates.from,
          _lte: searchDates.to,
        },
        ...(searchDates.product
          ? {
              product: {
                _eq: searchDates.product,
              },
            }
          : {}),
      },
    },
    fetchPolicy: "no-cache",
  });

  const handleRemoveHistory = (idx: number, stock: HasuraStock, taken: number) => async () => {
    const sure = window.confirm("¿Estás seguro de eliminar este registro?");
    if (!sure) return;
    const unused = stock.unusedQty + taken;
    await updateStockHistoryMutation({
      variables: {
        id: stock.id,
        _delete_at_path: {
          history: idx + "",
        },
        unusedQty: unused,
      },
    });
    refetchAggregations();
    refetchStockEntries(searchDates);
  };

  if (!stockData) return <div>Cargando...</div>;

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    refetchStockEntries(searchDates);
  };

  const handleSearchFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setSearchDates({ ...searchDates, [name]: value });
  };

  return (
    <Layout title="Inventario" full>
      <StockEntryModal
        open={newEntryModalOpen}
        setOpen={setNewEntryModalOpen}
        onCreated={() => {
          refetchStockEntries();
          refetchAggregations();
          setNewEntryModalOpen(false);
        }}
      />

      {editingEntry ? (
        <StockEntryModal
          entry={editingEntry}
          open={editEntryModalOpen}
          setOpen={setEditEntryModalOpen}
          onCreated={() => {
            refetchStockEntries();
            refetchAggregations();
            setEditEntryModalOpen(false);
          }}
          onCancel={() => {
            setEditingEntry(null);
          }}
        />
      ) : null}

      {giftingModalOpen && giftingEntry ? (
        <StockGiftingModal
          entry={giftingEntry}
          open={giftingModalOpen}
          setOpen={setGiftingModalOpen}
          onCreated={() => {
            refetchStockEntries();
            refetchAggregations();
            setGiftingModalOpen(false);
          }}
          onCancel={() => {
            setGiftingEntry(null);
          }}
        />
      ) : null}

      <div className="flex flex-col sm:flex-row">
        <div className="sm:w-1/3 sm:pr-4">
          <button onClick={() => setNewEntryModalOpen(true)} className="btn mb-4">
            Agregar Inventario
          </button>
          <h3 className="mb-3">
            <strong>Inventario Total Actual</strong>
          </h3>
          <StockAggregations />
        </div>
        <div className="flex-1 mt-5 sm:mt-0">
          <div className="flex flex-col sm:flex-row align-bottom mb-4">
            <form
              onSubmit={handleSearch}
              className="flex-1 flex sm:flex-row mb-4 sm:mb-0 flex-col sm:items-end order-2"
            >
              <div className="flex flex-col mr-2">
                <label htmlFor="fromField" className="text-xs">
                  Desde
                </label>
                <input
                  type="date"
                  name="from"
                  id="fromField"
                  value={searchDates.from}
                  onChange={handleSearchFieldChange}
                  className="border rounded p-1 text-sm"
                />
              </div>
              <div className="flex flex-col mr-2">
                <label htmlFor="fromField" className="text-xs">
                  Hasta
                </label>
                <input
                  type="date"
                  name="to"
                  id="fromField"
                  value={searchDates.to}
                  onChange={handleSearchFieldChange}
                  className="border rounded p-1 text-sm"
                />
              </div>
              <div className="flex flex-col mr-2">
                <label htmlFor="fromField" className="text-xs">
                  Producto
                </label>

                <select
                  id="product"
                  name="product"
                  value={searchDates.product}
                  onChange={handleSearchFieldChange}
                  className="border w-full py-1 px-2 rounded text-sm"
                >
                  <option value="">Selecciona uno</option>
                  {PRODUCTS_MAPPER.filter((p) => !REMOVED_PRODUCTS.includes(p.id)).map((prod) => (
                    <option value={prod.slug} key={prod.slug}>
                      {prod.return}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn mt-2 sm:mt-0">
                Buscar
              </button>
            </form>
          </div>

          <div className="mt-6">
            <h3 className="mb-3 text-lg">
              <strong>Entradas</strong>
            </h3>
            {stockData?.stock?.length === 0 && <div>No entries found</div>}
            <div className="hidden px-2 sm:px-4 py-3 md:flex items-center text-sm text-gray-600">
              <span className="sm:w-1/6">Fecha Creacion</span>
              <span className="ml-2 sm:w-1/6 flex-1">Producto</span>
              <span className="ml-2 sm:w-1/6 flex-1">Lot Id</span>
              <span className="ml-2 sm:w-1/6">Disponible / Total</span>
              <span className="ml-2 sm:w-1/6">Ordenes</span>
              <div className="w-16"></div>
            </div>
            <ul className="shadow rounded">
              {stockData?.stock?.map((entry) => {
                const calculatedUnusedQty =
                  entry.quantity - (entry.history?.reduce((acc, curr) => acc + curr.taken, 0) || 0);
                const usedMatchesCalc = calculatedUnusedQty === entry.unusedQty;

                const hasDoubledOrders =
                  (entry.history
                    ?.filter((h) => h.orderId)
                    .map((h) => h.orderId)
                    .filter((v, i, a) => a.indexOf(v) !== i)?.length || 0) > 0;
                return (
                  <li key={entry.id}>
                    <div className={`block`}>
                      <div className="px-2 sm:px-4 py-3 flex items-center border-t border-gray-200">
                        <span className="text-xs text-gray-600 sm:w-1/6">{dayjs(entry.date).format("MMM DD, YY")}</span>
                        <span className="ml-2 sm:w-1/6 flex-1">{entry.product}</span>
                        <span className="ml-2 sm:w-1/6 flex-1">{lotIdToFormat(entry.lotId)}</span>
                        <span className="ml-2 sm:w-1/6 font-mono flex items-center">
                          {entry.unusedQty} / {entry.quantity}
                          {!usedMatchesCalc ? <ExclamationCircle width={15} color="red" className="ml-2" /> : null}
                          {hasDoubledOrders ? (
                            <ExclamationCircleOutline width={15} color="red" className="ml-2" />
                          ) : null}
                        </span>
                        <span className="ml-2 sm:w-1/6 flex items-center">
                          {entry.history?.length ? (
                            <>
                              <span className="mr-1">{entry.history?.length}</span>
                              <Dropdown Icon={InformationCircleOutline} position="bottom">
                                <div className="p-2">
                                  <ul className="space-y-1">
                                    {entry.history.map((h, idx) => {
                                      const date = h.date ? `- ${dayjs(h.date).format("DD/MM")}` : "";
                                      return (
                                        <li key={idx} className="text-xs">
                                          {h.orderId ? (
                                            <Link to={`/orders-read/${h.orderId}`} className="text-indigo-600">
                                              {h.taken} - {h.orderId?.split("-").pop()} {date}
                                            </Link>
                                          ) : (
                                            <span>
                                              {h.taken} - {h.reason} {date}
                                            </span>
                                          )}
                                          {isDebug ? (
                                            <button onClick={handleRemoveHistory(idx, entry, h.taken)}>x</button>
                                          ) : null}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </Dropdown>
                            </>
                          ) : (
                            <span className="text-gray-500 text-sm">-</span>
                          )}
                        </span>
                        {/* <span className="ml-2 w-1/6">{entry.creator}</span> */}
                        <div className="w-20 text-right flex space-x-3">
                          <button
                            className="text-sm text-right"
                            onClick={() => {
                              setGiftingEntry(entry);
                              setGiftingModalOpen(true);
                            }}
                          >
                            Extras
                          </button>
                          <button
                            className="text-sm text-right"
                            onClick={() => {
                              setEditingEntry(entry);
                              setEditEntryModalOpen(true);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Stock;
