import { useMutation } from "@apollo/client";
import { Check } from "heroicons-react";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import React, { useEffect, useState } from "react";
import { UPDATE_ORDER_MUTATION } from "../utils/gqlQueries";
import Dropdown from "./Dropdown";
import Modal from "./Modal";

TimeAgo.addLocale(es);

const LOT_VALS = "lot_vals";

interface LotVals {
  lot?: string;
  expiration?: string | number;
}

const setStoreValues = (obj: LotVals) => {
  window.localStorage.setItem(LOT_VALS, JSON.stringify(obj));
};

const getStoreValues = (): LotVals => {
  const val = window.localStorage.getItem(LOT_VALS);
  return val ? JSON.parse(val) : {};
};

const OrderContextMenu = ({ order, refetchOrders }: { order: HasuraOrder; refetchOrders: Function }) => {
  const [lotModal, setLotModal] = useState<boolean>(false);
  const [expiration, setExpiration] = useState<string | number | undefined>(order.expiration || getStoreValues().expiration);
  const [lot, setLot] = useState<string | undefined>(order.lot || getStoreValues().lot);
  const [updateOrderMutation] = useMutation(UPDATE_ORDER_MUTATION);
  const handleModal = () => {
    setLotModal(!lotModal);
  };

  useEffect(() => {
    setLot(order.lot || getStoreValues().lot);
  }, [lotModal, order.lot]);

  const handleLotUpdate = async () => {
    setStoreValues({ lot, expiration });
    await updateOrderMutation({ variables: { id: order.id, data: { lot, expiration } } });
    setLotModal(false);
    if (refetchOrders) refetchOrders();
  };

  return (
    <>
      <Modal open={lotModal} onCancel={handleModal} okText="Guardar" onAccept={handleLotUpdate}>
        <>
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Editar Lote
          </h3>
          <div className="border-t border-gray-100 my-5"></div>
          <form>
            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="lot">
              No. de Lote
            </label>
            <input
              id="lot"
              defaultValue={lot}
              onChange={(e) => setLot(e.target.value)}
              className="border w-full py-1 px-2 rounded tetx-sm"
              type="text"
              placeholder="# Lote"
            />

            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="expiration">
              Fecha de vencimiento
            </label>
            <input
              id="expiration"
              defaultValue={expiration}
              onChange={(e) => setExpiration(e.target.value)}
              className="border w-full py-1 px-2 rounded tetx-sm"
              type="text"
              placeholder="Fecha de vencimiento"
            />
          </form>
        </>
      </Modal>
      <Dropdown>
        <div className="border-t border-gray-100"></div>
        <button role="menuitem" onClick={handleModal} className="btn-dropdown">
          <Check size={20} className="text-gray-500 mr-2" />
          Editar Lote
        </button>
      </Dropdown>
    </>
  );
};

export default OrderContextMenu;
