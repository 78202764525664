import React from "react";
import { findMapperMatch } from "../utils/prodsUtils";
import { lotIdToFormat, lotExpDateToDisplayFormat } from "../utils/stocking";

interface IOrderDetailStocks {
  order: HasuraOrder;
  item: HasuraOrderItem;
}

export default function OrderRow({ item, order }: IOrderDetailStocks) {
  const slug = findMapperMatch(item.name)?.slug;
  const lots = order?.lots?.filter((lot) => lot.product === slug);
  return lots && lots.length > 0 ? (
    <div className="px-4 pb-4 flex flex-col sm:flex-row items-center sm:px-6 text-xs font-mono text-gray-700">
      <ul>
        {lots.map((lot) => (
          <li key={lot.id}>
            <div>
              Lot: {lotIdToFormat(lot.lotId)} • V: {lotExpDateToDisplayFormat(lot.expDate)} • Qty: {lot.taken}
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
