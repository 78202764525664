import { AlegraItem } from "alegra-node-api";
import React from "react";
import { PRODUCTS_MAPPER } from "../utils/prodsUtils";
import { getTotalItems } from "./StatusTable";
import { useStockAggregationsV2 } from "./useStockAggregations";

interface IProps {
  products: AlegraItem[];
  orders: HasuraOrder[];
}

function StatusTableAggregations({ products, orders }: IProps) {
  const { data: aggr } = useStockAggregationsV2();
  return (
    <tr>
      <td>Stock</td>
      <td></td>
      {products.map((prod) => {
        const prodMapper = PRODUCTS_MAPPER.find((p) => p.id === prod.id);
        const prodStock = (aggr || ({} as any))[prodMapper?.slug || ""];
        const stockDelta = (prodStock || 0) - getTotalItems(orders, prod);
        return (
          <td className="text-center font-mono" key={prod.id}>
            <div className="flex flex-col">
              <input
                type="text"
                className="border p-1 w-12 m-auto text-center"
                disabled
                name={`calc-${prod.id}`}
                value={prodStock || ""}
              />
              <span className={`mt-2 font-bold ${stockDelta > 0 ? "text-green-600" : "text-red-500"}`}>
                {stockDelta}
              </span>
            </div>
          </td>
        );
      })}
      <td></td>
      <td></td>
    </tr>
  );
}

export default StatusTableAggregations;
