import React, { useContext, ReactElement } from "react";
import { AlegraContact } from "alegra-node-api";
import { useParams } from "react-router-dom";
import { api } from "./api";
import slugify from "slugify";

export const createCustomerUrl = (contact: AlegraContact) => {
  const client = btoa(slugify(contact.name));
  const clientToken = btoa(`13:4:50:23:125:8:54:${contact.id}:31:56:120:${client}:21:54:82:209`);
  const clientSlug = slugify(contact.name, { lower: true, strict: true });
  return `${window.location.origin}/clients/${clientSlug}/${clientToken}`;
};

export const getCustomerFromUrl = (token: string): { id: string; name: string } => {
  const parsed = atob(token);
  const split = parsed.split(":");
  const id = split[7];
  const name = atob(split[11]);
  return { id, name };
};

export const CustomerContext = React.createContext<AlegraContact | undefined>(undefined);

export const CustomerProvider = ({ children }: { children: ReactElement }) => {
  const params = useParams<{ token: string; clientSlug: string }>();
  const { id, name } = getCustomerFromUrl(params.token);
  const { data: client, error } = api.contacts.getById(id);
  
  if (error) {
    return <div>Hay un error. Por favor intenta mas tarde</div>;
  }

  if (!client) return <div>loading...</div>;

  if (window.FS) {
    window.FS.identify(client.id, {
      displayName: name,
    });
  }

  if (client.id === id && slugify(client.name) === name) {
    return <CustomerContext.Provider value={client}>{children}</CustomerContext.Provider>;
  } else {
    return <div>Please review your link</div>;
  }
};
export const useCustomer = () => useContext(CustomerContext);
