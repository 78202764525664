import imgMacadamia from "../assets/macadamia-100.jpg";
import imgAlmendra from "../assets/almendra-100.jpg";
import imgMaranon from "../assets/maranon-100.jpg";
import imgCocoa from "../assets/cocoa-100.jpg";
import imgMani from "../assets/mani-100.jpg";
import imgPink from "../assets/pink-100.jpg";
import imgMatcha from "../assets/matcha-100.jpg";
import imgGolden from "../assets/golden-100.jpg";
import imgCanela from "../assets/canela-100.jpg";
import imgPistacho from "../assets/pistacho-100.jpg";

const THE_PRODS = [
  {
    id: 20,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Negociación por insolvencia",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: 21,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "ICUI pendiente de cobro por cambio de tarifa del 10 % al 15 %",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 1,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "service",
    },
    tax: [],
    customFields: [],
    productKey: null,
    type: "service",
    itemType: null,
  },
  {
    id: 26,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Pistacho 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 196332,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 10000,
      initialQuantityDate: "2024-09-09",
      unit: "unit",
      unitCost: 137432,
      availableQuantity: 10000,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000,
          availableQuantity: 10000,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 25,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Pistacho 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 25714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 25378.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 32000,
      initialQuantityDate: "2024-07-15",
      unit: "unit",
      unitCost: 18024,
      availableQuantity: 31292,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 32000,
          availableQuantity: 31292,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 16,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 14874.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 15042.2,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 8750,
      availableQuantity: 6363,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 6363,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 17,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 15210.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 15714.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 7446,
      availableQuantity: 9320,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 9320,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 18,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Almendra 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 12521.5,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 13782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 5365,
      availableQuantity: 9170,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 9170,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 19,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Maní 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 5546.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 5798.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 20000,
      initialQuantityDate: "2022-08-08",
      unit: "unit",
      unitCost: 2820,
      availableQuantity: 10744,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 20000,
          availableQuantity: 10744,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 22,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Cocoa 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 5000000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 9122,
      availableQuantity: 4999640,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 5000000,
          availableQuantity: 4999640,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 23,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Marañón Canela 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 16555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 16134.45,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 10006,
      availableQuantity: 49661,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49661,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 24,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Flexible Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23109.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 22773.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 50000,
      initialQuantityDate: "2024-04-01",
      unit: "unit",
      unitCost: 13875,
      availableQuantity: 49494,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 50000,
          availableQuantity: 49494,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 13,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Canela 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 35100,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000000,
      initialQuantityDate: "2020-09-20",
      unit: "unit",
      unitCost: 25079,
      availableQuantity: 999994604,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000000,
          availableQuantity: 999994604,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 21,
        name: "marañoncanela 2020-09-20 at 2.56.09 PM.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1600632668-mara%C3%B1oncanela+2020-09-20+at+2.56.09+PM.png?Expires=1726674822&Signature=B1SirGScPeIKy2qDJyIkLYCiBbQ17K3TbXdfJTdOHV9IAi5-DpvmUfTW97-JE-oqjj7MxnfTRkxnOqU0nj7QGiS6AX8Kfbt1U3bvl5JbVvXPommOu-g8nRGPRfHwUyXWHJF5SwtJ6BpDdxqnm6RogxaAQwIQ4oZqKmR2v37J70t8mWFwtqqEEs1oQuD954rWQKuwJg3IbClpD7u~rwAM3cTediF0bvmWaM-PEL5TlswQDxFKNW-~57y8JrNlFGFddo6ygR7obcmNinaTWXSlER0FpVokvObaRDaOBmEp8fQe7Z63wHy1LxWNJXDIURTQSfCzTLabRZZ8B8b-WW8MqA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 3,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 33277.99,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 26555,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 33782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 21712,
      availableQuantity: 959358,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 959358,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 1,
        name: "Marañon.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1593882160-Mara%C3%B1on.jpg?Expires=1726674822&Signature=EhXxXfST3E6bvsloagIZaoGhAYkbbJsO~DT967Hz1~UIMJRNjoYH3~z57S6dneRKLCkeZuVUmH7zWb-LbPZIw93UJqxmPdIt0Pu8PUTaNIWB1nm0KCUSW0j4LAb0-RvptV5Z5~x7kUJQ~6r~2tAqlBRIBWi35K1dBVqCb2W3w2uPjtSV1qvjOvJ4C2sWNWjMs1OFXoL~eafxRnhPWVDHnyJAxF7O5XayF~wXX7tR0VylGkQPKf2J88HmsYyYcjEbQzB5ooVFL1fIMCvx-8OggkLtM709J~GNlqaniCm7IAuKdosUfrQSYsUgKF~O5q377OdGYFfFwR7~rFrHLWTPRg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 19,
        name: "maranon_1270bf70.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575349-maranon_1270bf70.jpg?Expires=1726674822&Signature=R1YE3dCfVVIXlPylwjwl4Exekk2Y4bcvDoNGfj0D4~SE2ItSNd3mRx6F80dyH2fH270qOCDvI4GTEwPf67Rn7iRxOYoqfR11JatReiqganCgE7wkxXqiMCHwuoJE3Z6An9yrnMp3kIP4HW5Bq0dHFeUYJONSxQQ8j2Z4Sf8tU9Nzk5hc4w8RkLb30IwOAHAUrdsbupy1ztvkXpCS6jL6LT-2WZNXPC3MeQn1EoH2s0ZJvlYdjwvXe22~OW7voD34Jj0qM5KjEi7GntI~NkgHFBKqRwdLP2bsJHkA9AXCgoWCYLtjjjmbwWZ19CBb4m0bVMq4Whr68oCENIPR4czP1g__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 5,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañón Cocoa 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 36303,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 30252,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 36891,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 22528,
      availableQuantity: 9990622,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9990622,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 12,
        name: "Imagen4.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594574048-Imagen4.png?Expires=1726674822&Signature=ZjGXtPQb709yVKwUeL54SBlfHjQn3Nxe9eu-ebSbdxejsJ3Q6KqA6ZOB~-qqLN-WzkdW1KV~AWQbgkIPccg901MfE7pm2OLpG7GDg~~Op39CYZFBByLZVpHlH9sjSqLYeyubFvKrNiSNDVn5QU3JCbpwxFkTbNuOJxeE3eSrZ2BgfVOfoKp7TRNyUmt3RrA6peasSHPgQGuJcqPkL5Dzmq68wzdjGt0r1j0Fg-cDDVN~63IJFXnyNdck73IM0uGnOwlnTbj4M4oUMZxm-ewib-l9SBzCZbjhrIJ1Cz0zVJB8JEEsLyMm8yHXbwustKxX07qEHBOq3ZxgXk9voiPEIQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 20,
        name: "maranoncocoa_10ee8637.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575358-maranoncocoa_10ee8637.jpg?Expires=1726674822&Signature=aUGSeXkLnWEi-vZ5sEU52dcWNxZXHLH~DNRaXl7sKib7Hj2BcuLhiAogCLr9iQHzyzyt0wLdreHYDzaAJTZ7g~PwbWz0sSKzRtmhfYi6bhI5nz7~39QVr1joqfTlqvPMmKQRowS~rKbvU-c6YBnxQkrscQxyKbYhcH0I3J4LZHtYIXGmxk8iXC7y8r-Lmf~MPgCWiw4O4ON87gSHOAR7j5LwvSDdulelf3hPl6JykxCDHyHrf5If2XmNVXnzZ0WgMRD7qLtsNF2H6byd6iNyUg9wT80f3gIk7kZvZOkww4G0pembTc3GwIWzutMb1aWirGleBVX949NcqD1VGo3uUA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 1,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 28067.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 28571,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 30840.4,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 13588,
      availableQuantity: 962420,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 962420,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 2,
        name: "almendra.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076804-almendra.jpg?Expires=1726674821&Signature=KRTY4yOydIbxd2qSGFQoKSwNEgPhKN5OJni~CnZJfu94WcbRvj5uMsk9442OoKm2XwYR~8PU~ftfPhOmy7vHkrgPvnRFI3Sxrn9PpXVSD3oZ203aJHDu0Ni1B7DwaM4jYHYhkNFq7Ev4T-udvmu9Cz80ne3bDX65Ncgu~TcmybLwGbjx5DlEMWyW1GAGusOXuPLR5jr6eLrgJOEJlflEuEvNEyKKyaekIc8EZzhaNWNXcu4utopqheOHajf6LT3FTRFs09NNuQitKH9NN87Fu6PMntK8WBIu3sWHW9TWHdRPUboq2A2z3bEHSg78TEKBVn5FkJW~lp6qRC7SzgEXDQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 13,
        name: "almendra_63f7dee4.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575284-almendra_63f7dee4.jpg?Expires=1726674821&Signature=CCQLPtYToiSlX0LU1dvf7Anw1Lcs-mNI7iPpavdvn9xoMX0oD8tudYepoiSRISKOq7I50aNWE5MIHiLtNkTt3Oaxl72lR9SQAFWMQmA7GsK9JjXp70pPTV3iYdaon8vGTlWzrQ24KN881V1OcKNJ--SZvlQnjGvPRaNDvp-Nf9Jx~vbYxssMomb9G-S116hT5xkPm9kYjhTfO8jK4AgLtHos0VYn2oaoo~zECRmiFXz-ccmfSEeMH~oE8dloQvki8P8QmvFDG-pbbM4qKj96fWeCzb2aYZnYJZA3vpCObK-Xa9CpviH9mmqKwtJ83NBmZOqRaYPk1ZEMDgbsHXCo5A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 2,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia 230g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 34622.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 32857,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 35126.3,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-04",
      unit: "unit",
      unitCost: 18583,
      availableQuantity: 965997,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 965997,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 3,
        name: "Macadamia.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076829-Macadamia.jpg?Expires=1726674822&Signature=EIk-jqT3-YfvriED-Br1vVMjCpdg~eViJnJVGdSa5cNAZYQp9a28OwL-LVqWXCmzYphfNdhUIGgksaJ7BIaeoVXjbztGHwNkrxHk5Fy8asqTQaGEypzSVpKeZuzS9Z2G1o20o3kj2P-YOEhlvCUYMi99eJinoevzQQTaYs~ozhxxK6-A~h1vUONXiik1CxM7Q69lVM9MdSe45Lf8Ocj9EcuwiDb5EliNHJn~feuiPN93c6SzDrltXDAiJTcWPKTy1QvG1vNdjQahos1N-HWiL8tXY5nPM9IrUO~to9m9npOuqc0PtHdqRqLGctMrQqLFVEaufmdgZRqkz~AprVr10A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 14,
        name: "macadamia_2eff4f33.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575296-macadamia_2eff4f33.jpg?Expires=1726674822&Signature=gdVce3hY5vYPzBSIOzKNyz0TpjSrumBZA9tItJnQCJyLaPthyUuBhG6bCnufzWMa7DKsNXugxcR6D52djm0Qn9R7K8iewkLDd-uKzl-Fb1ViJDG7Wc7bkY4Blh9FjfEhlHD16lRiaU9sUINtFS8kFq4GqDupWGmdMoCzFnfBPENJhv2buFGq3AYhj7OUHO-d2vFuvP34HiNqQwWA-8p5AKtTbNJHwC7TotuHz9eFdDU4Xt0lbwXYcX-Dk1aRatAGYKs5oUTbMMuUWdV87U0MwyMg5VAy6npdEbRqD25pkQfzGGMe6Y7qgXGrMT7ez0xF7XMcJHCgcmzbLNPV6vBPsQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 4,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 240g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 12269.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 10924,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 12941.25,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 7480,
      availableQuantity: 964991,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 964991,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 7,
        name: "Mani.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076973-Mani.jpg?Expires=1726674822&Signature=cqIEKLFrHohGAAmCm6n8zmxNv55-QkkmZZQ8NUcjXO5f2TRmwm4Vhe~5KNHFw8kDo~SXjQXhw2~REsj2XOMIf84zIiNluCNG1utXRJtEF-R6kYvLRkYzVm152N-qgtVpb2c2ZunS4NY66Z0BjSCXUJ6f5g9s9Bc-xoxbohQrMZRTiKE9bL7ReZqxMCMxUEGQRJWWBt1zP4vzRgeEFI8-sMD~tsamG8qC0x~mapU4ccniylh0C-RaK5KLF4Yay8vAMbQxgOOQg090snTeTpzwUbmOv2X-LrlwDyUvzEnaBUIpOanOEYsr-QCGX6p~jg8sqiXvLysWjDYEJQuz1jS3vg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 18,
        name: "mani_78354541.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575340-mani_78354541.jpg?Expires=1726674822&Signature=AjOmKS9mgr6uZz39eNy4thqwIK98CJMMIPx5xpVxBOjYX6uuXfZaQiSmUnh7xhsPx3ACSCFuBbsxd1HwCA87ZIetruovEZ5PEwBh06hB1G3KYiCh9v7emalVtHB58bGdqgaciqdvdvBLCQzVbc-qYRyy5X2xe2DvSB4HJRfCf0XsYbeBUUx3g7Kg3UDOD5QHkLC~OWdN-ui4dMSs~MbAWpXkQT8aVIVZ1-JhRzFrFR2jb7RM9S418OtbGkL1Ezpe7dEyEbTGzWT9dhoqMZR0BGS~90DIzG5qbMsXMiPdIR3fbC77xWjVrgiqMqbApluVn-zIF9shJ3xIOQGCbtzt0A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 6,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Pinkfud 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 21932.77,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 10000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 14968,
      availableQuantity: 9988219,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 10000000,
          availableQuantity: 9988219,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 6,
        name: "Pink.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076956-Pink.jpg?Expires=1726674822&Signature=XkGPZ7jWyoarZ9Gxy3wMV0cwclmD8E7h6kV0pOu7-fZ7EQgKPSfEOMkfcJCWg0tSD5XRz9HgNt55sEAb4zkg2pUNvrJ-KmKPvTgGk2KdYSSh5klf5ofJZfeWdX4Ym7WpN62WkJ6rkCPSZ9m~4~Xgxy6dDOi82mvMgfcC1vnYEO8BbhTYOwSxPH-jlaz6LCDLyDkiYaTJ4DHL8TO6Wep1HbElRb~OmRgpfaAWJTPBYTLmDdCV7fNfLncfXsaCPgpmhf0IuCyWoF8O3~Um-Y~NfpsN930as4IydbBrUYMkjo33XkslyMxTKVsmVTJNSUA~c1R3RYS2oaDbVemJkjsCzw__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 17,
        name: "macadamia-pinkfud_45ab54cc.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575328-macadamia-pinkfud_45ab54cc.jpg?Expires=1726674822&Signature=IY7uSNHxC5y2OT3vqDQGoGpMhZ8rdsvjwH2-j5mb4kw8xV1yGXfCUnwiTKtczt2TgiGiglQ4dnlxsRhKBstvX2wj9lEzJPTyoTVsa9QVF9jQcPWaqPNEznJInvqFZC08v4sxj29tYu7FIDriT4HJ3EPCC~GuXXMu4hMrJdDR9is0tzVCFq1-AvKPuHmhBzJHaKt73KeTbtJH8ZIbLtMo99ip3Ng9Ia496eve6pdw-Msc2p8D~SJuAUYVVriVTVhVa7-9TkH8U7bI~cUFwfYjci1SqL2IsxKlYsk28VJiM6UT9PyWn-sF7vpMnxynyhImLOMBSmj12ndd1obyyBPMMw__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 7,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 20168,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23950,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11161,
      availableQuantity: 97512,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 97512,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 5,
        name: "IMG_20200622_145520.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076908-IMG_20200622_145520.jpg?Expires=1726674822&Signature=aWoXBWeu6ARlBVsKl657H-umuZdQvXvGGoCh2kBMd12cq3RuqaBYsVl5hg~OZ39loza2MA7CIwQ6n~5MFfVAb7-nvRW29mtSLNvB4xE4gHuStcXsCBP7YSeQvGwT47DDMVPYL7RV2KDBoFzvLEMf8kSdgvEWmk8wpM9RnoFMqWc8g3C0IT5pB~cPn3DTDhKpriAMnSeButHb2EFS1~z1UR-~Q~OHF4qyGb2own32cYqhOGG9L-~0k9~IwVIYKAhXLuwZcYKj440Xz6zYUAg5vKD-G3fs8SCnwIob570-YpdbLsssR91kTeIA-aHEeMiCDu9sR7IZXaVKe34P-KehLQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 8,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077024-Matcha.jpg?Expires=1726674822&Signature=PU9xQQZJQ317mwoDjMFros8Xchdrilpd~BdPptqBehFNF6F1h1Mea3OgZvRIiridnN8ZPcXsapo4-kyU9MgTOWW8qSfzqtM2MGBvTdCHBsdN~crQblizYOYUKwL3WI-nlyX5W1Ft6FznUhgtI8rS8fulv9Tq~O~oHLW1A~e3CxVHlTviB7GdIiUCf51vwly57-pTVT9owerJTaT83h-lw5YM-tFfZU9nIAgs~Hc~rdxzFHVPFBiOPRnYANeAaSxjNkaiLYaPvU3sEIrIk9yoyL1qhK76JaGPqst3yYVDp5fbEEsR-J~lt5k41soJPiKEYIgBYrN9xVfykdxed13mBA__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 9,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077114-Matcha.jpg?Expires=1726674822&Signature=HMRxoWKq5cbZZZoHzJuJsGpCnseW7NvgxpO56oTcoAXW73MsUUuMZTRwQgx2nCNbMIh9q98MHEDI8iaeGcOwg3HhgbOX4dSJXQ7cMQpPwrZspdU8WCLlPL6ELDfShvM0rUskoaHLdVNoq8zvwGVjKsvAwaJesl-jcmYRYgd~H2-kLIdrLCLt2RtBhCfcUvDZfHxuvAuUCQ3GkqhYAhYbAQrWXkTPr2apnLy6-QIvBo423BkUom5Qmn5IAT0hyNl~Wwbst7Pw4WiPgdnZptXWCvF0SQ1AOw--GaxQEZctkhklilp73WtrIINPbT89Jtz2S8QSWgKVpHddp9O3fJRH8A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 10,
        name: "Matcha.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594077215-Matcha.jpg?Expires=1726674822&Signature=nzKFH0TeSa93nL7PWqMnNZSNte7WD99r~C11glfCFktTTD2QnKODZhyRLVEb7CpTcVSYfkYNhxev5-J~GvhkCrEfguGn2icE3VIMh1UvhpaOzxl7DY1F9sA7UsabJzkeJCWUDFcM5itohMSmyVWBN1ska63KfqB2dX~uhoJ~InauYbezHD-uqPnfT5enuyWT6Z1OE~3gzuMB2Jz1U-FN~UpcVjU5M4ghaNlbZdf5r7fq-0xXFG4-nNZs-XVRAZBlI188gaXr5vB~OdBhBcd7bLlLJqGlQr9nF6QFmquf9DphZoxoLcBZIZvDsU458FF-RJ0LEIcPtq7cgl19a6L2ng__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 16,
        name: "macadamia-matcha_966cbdef.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575319-macadamia-matcha_966cbdef.jpg?Expires=1726674822&Signature=ikDgUxoCsk7tPge522RSCt97w291RZn-RdnxengjK7i6o7Xq4hP7VqbRc51mliK9l1MfYU8ekzkEl2U2f~bZ3lmF-J759PuhPosnZbcSyYw5bDhjwd5vIdfp2WhozTI9MG4Q27dm9bI6lbcR9Co58Sd94JtHG1i98RXUvwJ9n5aoa-Xfb5UDHZrqMWCaXaNXRI5bo9E7pENue-iO7tGupt0as3xFU3JJlSLBGNGFpXYC6IOt6LKWd39jCxnyWw1gcfcvXkytPs6XJjFe5OW7ZZl6xuxKEE2AqC57kHp5b-Ra9c-O63SuAtJ22qvgM4G9bjFqkdGCFbKUDJb5GUzu1A__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 8,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Golden Mix 100g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
      {
        idPriceList: 2,
        name: "Salvatore",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 3,
        name: "PVP",
        type: "amount",
        price: 21848,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
      {
        idPriceList: 4,
        name: "2020 general",
        type: "amount",
        price: 23955.96,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: false,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 11346,
      availableQuantity: 997717,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 997717,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
      {
        id: "5",
        name: "ICUI",
        percentage: "15.00",
        description: "",
        status: "active",
        type: "ICUI",
        categoryFavorable: {
          id: "5013",
          idParent: "5010",
          name: "Otro tipo de impuesto a favor",
          text: "Otro tipo de impuesto a favor",
          code: null,
          description: null,
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "106",
            name: "Otro tipo de impuesto a favor",
            key: "OTHER_TAX_TYPE_IN_FAVOR",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5044",
          idParent: "5041",
          name: "Otro tipo de impuesto por pagar",
          text: "Otro tipo de impuesto por pagar",
          code: null,
          description: null,
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "105",
            name: "Otro tipo de impuesto por pagar",
            key: "OTHER_TAX_TYPE_TO_PAY",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    images: [
      {
        id: 4,
        name: "IMG_20200622_145613.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594076848-IMG_20200622_145613.jpg?Expires=1726674822&Signature=Z7HLxcZZHLTtOF8WhUnDF1nqvjXlIUTheLZUQYSJHMrRHYsN6D6JXVPjjH7Z34YXZjI-Px9Mk5~xk4TD9Mp96FuzFV8rSNQN76TuPZlmg6R9W89wvEOWMGwYTDzZQVMbXIyebcKRFcSgktHITFrzqYqzegD3jG4Lj6iK4Z3xi3T8T22L608Wi91veZQ3GCCTxn-YQh5afb0g5wPMWmxqjOGEf3hO~LdgYNVmrLMvQM7Ph-f-iT1XmxDPPqqfgqCYRUlfJ0ivcTnoI2rKueqdhHaTuRZMQ2JrP-qSd8ERTAmm0SQjUiNcegXc1C5gYseomYF4IvKZi~cW3KCE6g-oHg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 11,
        name: "Imagen2.png",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594573971-Imagen2.png?Expires=1726674822&Signature=pDr3q8znZs2sKo-uy39YUmwsF1i6aAhedb-5mwy2EhSaJcrqolw9sHtdtFtTyiTJTQDuigzoxyMgkDyF5kn8u3Dfs81Z13LfEQ8w6RIgBwArcYP3gcXppS9nf1kMrzmhSl8V5iY0b3LE7TcwUGUnOnxrFKjVJ1EWq3xXDXLLG1MNFwUEDoB7JbWBy-HuIisjUC5haNs0bb9LqbhNd6ofryQv5usZt2fdmJybrftP19P1FqrYDAnQnEXyb-xC3sUyQk4r-cpmz-0eutUdhE7WWFM25k-eMfwrTjeImSGtDuE2Hjr48bwNY5~eFCrBsUjxM1OWM902-sphtF8uZp-CbQ__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: false,
      },
      {
        id: 15,
        name: "macadamia-golden_eb7e5ceb.jpg",
        url: "https://cdn3.alegra.com/6e0b805c4d967fc5bbdf2ffc94b40a0fc4a4b9f9-1594575306-macadamia-golden_eb7e5ceb.jpg?Expires=1726674822&Signature=fatKPGLwxPHU~GOXBEBLVJlumESn3hVaZLZ4tpyep9QNn7Vrn4cnKTlaOWxY0cK8y2ayId4rTAP9tiznPUP39Zevbon4OWUIP4Z7dUlXBgVmoglqaF8RsBNjphC0yw-8~eU26AfsD~laMJAjOKeU~5yfpI633Ow3uBWPeEd5yMneaDdk~0Lfza06tAOERBaJ2BpKj-BaSz2DrtBmRkrS~dBj5LxCMLuu6kHSdxXvhVQeja5QNHokt6wZ9NVtJ4jS49rnTrbToXzA4TWNmjQgrtVJaMoGie9eEQn8qFBqcFY7OT2IpD~19CIu~4gEdG08m1j8ZHJpFLUHby2be795sg__&Key-Pair-Id=APKAJU3VE62QBWZP27QQ",
        favorite: true,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 12,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 1Kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 36050.42,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000000,
      initialQuantityDate: "2020-07-12",
      unit: "unit",
      unitCost: 20875,
      availableQuantity: 999488,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000000,
          availableQuantity: 999488,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 9,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Maní 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 16387,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 9685,
      availableQuantity: 874,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 874,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 10,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Macadamia Matchachá 500g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 53782,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 34685,
      availableQuantity: 995,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 995,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 11,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Almendras 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 60672,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 1000,
      initialQuantityDate: "2019-10-08",
      unit: "unit",
      unitCost: 33422,
      availableQuantity: 928,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 1000,
          availableQuantity: 928,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
  {
    id: 14,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Nueces 32g",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 2941.19,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      initialQuantity: 100000,
      initialQuantityDate: "2020-12-11",
      unit: "unit",
      unitCost: 1765,
      availableQuantity: 99860,
      warehouses: [
        {
          name: "Principal",
          observations: null,
          isDefault: true,
          status: "active",
          id: "1",
          costCenter: null,
          address: null,
          initialQuantity: 100000,
          availableQuantity: 99860,
          minQuantity: null,
          maxQuantity: null,
        },
      ],
    },
    accounting: {
      inventory: {
        id: "5023",
        idParent: "5002",
        name: "Inventarios",
        text: "Inventarios",
        code: null,
        description: "",
        type: "asset",
        readOnly: false,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "7",
          name: "Inventario",
          key: "INVENTORY",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
      inventariablePurchase: {
        id: "5070",
        idParent: "5069",
        name: "Costos del inventario",
        text: "Costos del inventario",
        code: null,
        description: "",
        type: "expense",
        readOnly: true,
        nature: "debit",
        blocked: "no",
        status: "active",
        categoryRule: {
          id: "33",
          name: "Costo de ventas",
          key: "INVENTARIABLE_PURCHASES",
        },
        use: "movement",
        showThirdPartyBalance: true,
      },
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "product",
    itemType: null,
  },
  {
    id: 15,
    category: {
      id: "5062",
      name: "Ventas",
    },
    hasNoIvaDays: false,
    name: "Crema de Marañon 1kg",
    description: null,
    reference: null,
    status: "active",
    calculationScale: 2,
    price: [
      {
        idPriceList: 1,
        name: "General",
        type: "amount",
        price: 90000,
        currency: {
          code: "COP",
          symbol: "$",
        },
        main: true,
      },
    ],
    inventory: {
      unit: "unit",
    },
    tax: [
      {
        id: "3",
        name: "IVA",
        percentage: "19.00",
        description: "",
        status: "active",
        type: "IVA",
        categoryFavorable: {
          id: "5011",
          idParent: "5010",
          name: "Impuesto a las ventas a favor",
          text: "Impuesto a las ventas a favor",
          code: null,
          description: "",
          type: "asset",
          readOnly: false,
          nature: "debit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "44",
            name: "IVA a favor",
            key: "IVA_IN_FAVOR_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        categoryToBePaid: {
          id: "5042",
          idParent: "5041",
          name: "Impuesto a las ventas por pagar",
          text: "Impuesto a las ventas por pagar",
          code: null,
          description: "",
          type: "liability",
          readOnly: false,
          nature: "credit",
          blocked: "no",
          status: "active",
          categoryRule: {
            id: "47",
            name: "IVA por pagar",
            key: "IVA_TO_PAY_COL",
          },
          use: "movement",
          showThirdPartyBalance: true,
        },
        rate: null,
      },
    ],
    customFields: [],
    productKey: null,
    type: "simple",
    itemType: null,
  },
] as const;

export const PROD_IMAGES = {
  1: imgAlmendra,
  2: imgMacadamia,
  3: imgMaranon,
  5: imgCocoa,
  4: imgMani,
  6: imgPink,
  7: imgMatcha,
  8: imgGolden,
  13: imgCanela,
  16: imgMaranon,
  17: imgMacadamia,
  18: imgAlmendra,
  19: imgMani,
  22: imgCocoa,
  23: imgCanela,
  24: imgPink,
  25: imgPistacho,
} as { [key: number]: string };

export const DISCOUNTS = [500]; // [13, 3, 5];
export const DISCOUT_PERCENTGE = 15;
export const hasDiscount = (id: number): boolean => DISCOUNTS.includes(id);

const notAProductId = [21, 20]; //insolvencia y otras cosas de Diego mr. accountant

export const PRODS = THE_PRODS.filter((i) => !notAProductId.includes(i.id)).map((i) => ({
  ...i,
  name: i.name.replace("Ã­ ", "í ").replace("Ã±Ã³", "ñó").replace("Ã¡", "á"),
}));
