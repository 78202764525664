import React, { useState } from "react";
import Modal from "./Modal";

interface IModalProps {
  open?: boolean;
  order: HasuraOrder;
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccept: (notes: string) => void;
}

const OrderContextEditNote = ({ order, open, onCancel, onAccept }: IModalProps) => {
  const [notes, setNotes] = useState(order.notes);

  const handleNoteUpdate = (e: any) => {
    onAccept(notes);
  };

  return (
    <>
      <Modal open={open} onCancel={onCancel} okText="Guardar" onAccept={handleNoteUpdate}>
        <>
          <form>
            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4" htmlFor="expiration">
              Notas
            </label>
            <textarea
              id="notes"
              defaultValue={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="border w-full py-1 px-2 rounded tetx-sm"
            />
          </form>
        </>
      </Modal>
    </>
  );
};

export default OrderContextEditNote;
