import { useQuery } from "@apollo/client";
import { AlegraItem } from "alegra-node-api";
import classNames from "classnames";
import { Table, ViewGrid } from "heroicons-react";
import React, { useState } from "react";
import { HasuraOrderStatus } from "../enums";
import OrderContextMenu from "../ui/OrderContextMenu";
import { api } from "../utils/api";
import { useHasRoles } from "../utils/authContext";
import { getTableFilterIds } from "../utils/constants";
import { GET_ORDERS_BY_STATUS } from "../utils/gqlQueries";
import { findMapperMatch, findNameMatch, findWeightMatch } from "../utils/prodsUtils";
import { lotIdToFormat } from "../utils/stocking";
import StatusTableAggregations from "./StatusTableAggregations"; 
import StatusTableForecast from "./StatusTableForecast";

export const getTotalItems = (orders: HasuraOrder[], product: AlegraItem) => {
  return orders.reduce((acc: number, order) => {
    const total = Number(order.items.find((i) => Number(i.id) === product.id)?.qty || 0);
    return acc + total;
  }, 0);
};

const getWeight = (orders: HasuraOrder[], product: AlegraItem) => {
  const grms = getTotalItems(orders, product) * findWeightMatch(product.name);
  const kg = grms / 1000;
  const num = Math.round(kg * 10) / 10;
  return `${num}Kg`;
};
interface IStatusTableProps {
  status: HasuraOrderStatus;
  onCheckboxChange?: (a: HasuraOrder, b: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxKey: keyof Pick<HasuraOrder, "productionReady" | "shippingReady">;
  view: "production" | "shipping";
}

function StatusTable({ status, onCheckboxChange, checkboxKey, view }: IStatusTableProps) {
  const { data, refetch: refetchOrders } = useQuery<{ orders: HasuraOrder[] }>(GET_ORDERS_BY_STATUS, {
    variables: { status },
    fetchPolicy: "no-cache",
  });

  const [displayView, setDisplayView] = useState<"TABLE" | "GRID">("TABLE");
  const orders = data?.orders;
  const isAdmin = useHasRoles(["admin"]);
  const { data: products } = api.products.get();

  if (!orders || !products) return <div>Cargando...</div>;

  const FILTER_IDS = getTableFilterIds(orders);

  const filteredProds = products.filter(({ id }) => !FILTER_IDS.includes(id));

  const headerTitles = (key: string) => {
    let flexCount = 0;
    let glassCount = 0;
    filteredProds.forEach((prod) => {
      if (prod.name.toLowerCase().includes("flexible")) {
        flexCount++;
      } else {
        glassCount++;
      }
    });
    return (
      <tr key={key}>
        <th></th>
        <th className="border-t-0"></th>
        <th colSpan={flexCount} className="bg-purple-200">
          <span className="font-bold">flexible</span>
        </th>
        <th colSpan={glassCount} className="bg-teal-200">
          <span className="font-bold">vidrio</span>
        </th>
        <th></th>
        <th></th>
      </tr>
    );
  };
  const headers = ({ inverted = false }: { inverted?: boolean }) => (
    <thead>
      {!inverted ? headerTitles("top") : null}
      <tr>
        <th style={{ width: 100 }}>Empresa</th>
        <th style={{ width: 150 }}>Sede</th>
        {filteredProds.map((prod, idx) => (
          <th
            style={{ width: 10 }}
            key={prod.id}
            className={classNames({
              "bg-purple-100": idx < 7,
              "bg-teal-100": idx > 6,
            })}
          >
            <span
              className="font-bold"
              style={{
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
              }}
            >
              {findNameMatch(prod.name)}
            </span>
          </th>
        ))}
        <th style={{ width: 100 }}>Total</th>
        <th></th>
      </tr>
      {inverted ? headerTitles("bottom") : null}
    </thead>
  );

  return (
    <>
      <div className="flex justify-end mb-4">
        {displayView === "TABLE" && (
          <button className="btn--outline" onClick={() => setDisplayView("GRID")}>
            <ViewGrid size={18} />
          </button>
        )}
        {displayView === "GRID" && (
          <button className="btn--outline ml-2" onClick={() => setDisplayView("TABLE")}>
            <Table size={18} />
          </button>
        )}
      </div>
      {displayView === "GRID" && (
        <div>
          <div className="bg-white shadow sm:rounded-md">
            <ul>
              {orders?.reverse().map((order, idx) => {
                return (
                  <li key={idx}>
                    <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out border-b border-gray-200">
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex justify-between">
                          <div className="mb-4">{order.client.name}</div>
                          <div>{isAdmin && <OrderContextMenu order={order} refetchOrders={refetchOrders} />}</div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start mb-2 space-x-10">
                          {filteredProds.map((prod) => {
                            const item = order.items.find((i) => Number(i.id) === prod.id);
                            const prodQty = item?.qty || 0;

                            if (!prodQty || !item) return null;
                            const slug = findMapperMatch(item.name)?.slug;
                            const lots = order.lots?.filter((lot) => lot.product === slug);

                            return (
                              <div key={prod.id} className="text-sm">
                                <div className="text-center font-mono mr-4 mb-2">
                                  {prodQty} {findNameMatch(prod.name)}
                                </div>
                                {view === "production" ? (
                                  <StatusTableForecast item={item} order={order} orders={orders} />
                                ) : null}
                                {view === "shipping" && lots ? (
                                  <div className="space-y-1">
                                    {lots?.map((lot, idx) => (
                                      <div
                                        key={idx}
                                        className="badge bg-gray-200"
                                        style={{ display: "block", width: "fit-content" }}
                                      >
                                        #{lotIdToFormat(lot.lotId)} {lot.taken}
                                        <span className="text-gray-600">/{lot.quantity}</span>
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>

                        <div className="font-mono mb-4 text-sm">{order.totalQty} total </div>
                        <div className="whitespace-normal badge bg-blue-200">{order.notes}</div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {displayView === "TABLE" && (
        <div className="overflow-y-auto-x">
          <div className="flex flex-col">
            <div className="align-middle inline-block w-full border-gray-200">
              <table style={{ minWidth: 1400 }} className="w-full table shadow sm:rounded-lg border">
                {headers({})}
                <tbody className="bg-white">
                  {[...orders].reverse().map((order, idx) => (
                    <tr key={order.id} className={`hover:bg-gray-100 ${order.productionReady && "bg-white-100"}`}>
                      <td>
                        <div className="flex items-center">
                          <span className="mr-2 text-xs text-gray-600">{idx + 1}.</span>
                          {onCheckboxChange && (
                            <input
                              type="checkbox"
                              className="mr-2"
                              id={`order-${order.id}`}
                              disabled={!isAdmin}
                              defaultChecked={order[checkboxKey]}
                              onChange={(e) => onCheckboxChange(order, e)}
                            />
                          )}
                          <div>
                            <span style={{ maxWidth: "10rem" }} className="truncate font-bold">
                              {order.client.name}
                            </span>{" "}
                            <br />
                            <span>{order.notes}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-xs text-gray-700">{order.comments}</span>
                      </td>
                      {filteredProds.map((prod) => (
                        <td className="text-center font-mono" key={prod.id}>
                          {order.items.find((i) => Number(i.id) === prod.id)?.qty || (
                            <span className="text-gray-500">-</span>
                          )}
                        </td>
                      ))}
                      <td className="text-center font-mono">{order.totalQty}</td>
                      <td className="whitespace-no-wrap border-b border-r border-gray-200 text-sm leading-5 font-medium text-gray-900 w-8">
                        {isAdmin && <OrderContextMenu order={order} refetchOrders={refetchOrders} />}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="table__gray-tr">
                    <td className="uppercase">Totales</td>
                    <td></td>
                    {filteredProds.map((prod) => (
                      <td className="text-center font-mono" key={prod.id}>
                        <span className="font-bold">{getTotalItems(orders, prod)}</span>
                        <div className="text-sm text-gray-600 mt-1" style={{ fontSize: "0.65rem" }}>
                          {getWeight(orders, prod)}
                        </div>
                      </td>
                    ))}
                    <td className="text-center font-mono font-bold">
                      {orders.reduce((acc, curr) => curr.totalQty + acc, 0)}
                    </td>
                    <td></td>
                  </tr>
                  <StatusTableAggregations products={filteredProds} orders={orders} />
                </tfoot>
                {headers({ inverted: true })}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StatusTable;
