import { difference } from "lodash";

// export const TAX = [{ id: "3" }];

export const REMOVED_PRODUCTS = [11, 10, 9, 12, 14, 15, 6, 26]; // only visible to admins
export const FLEXIBLE_PRODUCTS = [16, 17, 18, 19, 22, 23, 24, 25];
export const NEW_PRODUCTS = [22, 23, 24, 25]; //for showing confetti 🎉

export const CUSTOMERS_WITH_DISCOUNT = [
  {
    id: "153", // Fit Market S.A.S
    discount: 3,
  },
  {
    id: "529", // Negocios La  Plaza  S.A.S
    discount: 3,
  },
] as const;
export const CUSTOMERS_WITHOUT_MINIMUM = [
  "197", // Juan Pablo Solano
  "284", // Jenny rojas
];

export const PRODS_ORDER = [25, 16, 17, 18, 19, 22, 23, 24, 13, 3, 5, 1, 2, 4, 6, 7, 8 /**others */, 12, 9, 10, 11, 14, 15, 26];
// export const PRODS_ORDER = [13, 1, 2, 3, 5, 4, 6, 7, 8, /**others */ 9, 10, 11, 12];

export const getTableFilterIds = (orders: HasuraOrder[]) => {
  const foundIds = orders.reduce((acc: number[], order): number[] => {
    const found: number[] = [];
    order.items.filter((item) => (REMOVED_PRODUCTS.includes(Number(item.id)) ? found.push(Number(item.id)) : null));
    return [...acc, ...found];
  }, []);

  return difference(REMOVED_PRODUCTS, foundIds);
};
